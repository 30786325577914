import React, { Component } from 'react'
import ReactEcharts from 'echarts-for-react'
import "./pie.css"
class PieNot extends Component {

  state = {

  }

  componentDidMount() {
    // this.getOption(this.state.data)
  }

  componentWillReceiveProps() {

  }

  getOption = () => {
    const data = this.props.data
    const index = this.props.index
    console.log("data[index]",data[index]);
    const dataOption = JSON.parse(JSON.stringify(this.props.dataOption))
  
    const evaluateData = data[index].data
    const evaluatetitle = data[index].name_cn
    dataOption.title[0].subtext = `${evaluatetitle}危害商贡献率图`
    dataOption.series[0].data = Object.keys(evaluateData).map(item => {
      return { name: item, value: evaluateData[item] }
    })
    dataOption.series[0].name = data[index].mac1
    return dataOption
  }

  render() {
    // const { getOption, index } = this.props
    return (
      <ReactEcharts style={{ width: '33.33%', marginBottom: " 50px" }} option={this.getOption()}></ReactEcharts>

    );
  }
}

export default PieNot;