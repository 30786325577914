import * as React from 'react';
import { withRouter } from "react-router-dom"
import ForgetForm from "../../components/forgetForm"
import "../../assets/styles/forget.less"
import { maximizeWindow, closeWindow, minWindow } from '../../configs/electronHandle'
interface IProps {
  history: any
}

interface IState {

}

class Forget extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="forget-container win-drag">
        <div className='forget-container-box'>
        <div className="forget-container_fl fl">
          <div className="forget-container_fl_top_img">
            <img
              src={require("../../assets/images/login/login-fl.png").default}
              alt=""
            />

          </div>
          <div className="forget-container_fl_box">


            <div className="forget-container_fl_box_center">
              <h3>污染场地风险评估系统</h3>
              <div className="forget-container_fl_center_img">
                <img
                  src={require("../../assets/images/login/login-center.png").default}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="forget-container_fr fr">
          {/* <div className="forget-header ">
          <div className="forget-header-button-container">
            <div className="forget-space"></div>
            <img
              className="win-no-drag"
              src={require("../../assets/images/login/min_button.png").default}
              alt=""
              onClick={minWindow}
            />
            <img
              className="win-no-drag"
              src={require("../../assets/images/login/close_button.png").default}
              alt=""
              onClick={closeWindow}
            />
          </div>
          
        </div> */}
        <div className="forget-header-title">找回密码</div>
        <div className="forget-form win-no-drag">
          <div className="forget-space"></div>
          <ForgetForm></ForgetForm>
          <div className="forget-space"></div>
        </div>
        </div>
        </div>
      </div>);
  }
}

export default withRouter(Forget);