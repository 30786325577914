import * as React from "react";
import { Select, Tabs, Row, Col, Form, message } from "antd";
import FirstLevel from "./firstLevel"
import TwoLevel from "./twoLevel"
import { checkProductd } from '../../../configs/checkProductId.js'
import {getUser,setUser,getSessionProductId,getToken,getSessionLevel} from '../../../utils/token'
import { CheckOutlined } from '@ant-design/icons';

import "./generalReport.less";
import { firstReport, secondReport, reportPath } from '../../../apis/common'
export interface Props {

}

export interface State {
  base_info: any,
  soil_pollutant: any,
  water_pollutant: any,
  project_id: string,
  level: string,
  all_param_value: any,
  physical: any,
  project_model: any,
  toxicity: any,
  soilControl: any,
  waterControl: any,
  soilCancer: any,
  soilNotCancer: any,
  exsoilCancer: any,
  exsoilNotCancer: any,
  exwaterCancer: any,
  exwaterNotCancer: any,
  contributionsoil: any,
  contributionsoilNot: any,
  contributionwater: any,
  contributionwaterNot: any,
  soilgraphic: any,
  watergraphic: any,
  graphicArr:any,
  report_water: any,
  token:string,
  SessionProductId:string,
  User:any,
}

class generalReport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      base_info: [],
      soil_pollutant: [],
      water_pollutant: [],
      all_param_value: [],
      physical: [],
      project_model: {},
      toxicity: [],
      soilControl: [],
      waterControl: [],
      soilCancer: [],
      soilNotCancer: [],
      exsoilCancer: [],
      exsoilNotCancer: [],
      exwaterCancer: [],
      exwaterNotCancer: [],
      contributionsoil: [],
      contributionsoilNot: [],
      contributionwater: [],
      contributionwaterNot: [],
      report_water: [],
      soilgraphic: [],
      watergraphic: [],
      graphicArr: [],
      project_id: '',
      level: '',
      token:'',
      SessionProductId:'',
      User:[],
    };
  }
  componentWillMount() {
    let productId = checkProductd();
    if (productId) {
      this.setState({
        project_id: productId
      })
      this.getLevel(productId)
    }
  }
  getLevel = async (productId: any) => {
    let res = await reportPath({
      project_id: productId
    })
    this.setState({
      level: res.data.toString()
    })
    if (res.data == '1') {
      this.getfirstReport(productId)
    }
    if (res.data == '2') {
      this.getSecondReport(productId)
    }
  }
  async getfirstReport(project_id: any) {
    await firstReport({
      project_id
    }).then((res: any) => {
      let {
        base_info,
        soil_pollutant,
        water_pollutant
      } = res.data;
      this.setState({
        base_info,
        soil_pollutant,
        water_pollutant
      })
    }).catch(() => {

    })
  }
  async getSecondReport(project_id: any) {
    await secondReport({
      project_id
    }).then((res: any) => {
      let {
        base_info,
        soil_pollutant,
        water_pollutant,
        all_param_value,
        physical,
        project_model,
        toxicity,
        risk
      } = res.data;

      this.handleRisk(risk)
      let toxicityArr = [
        'ABSD',
        'ABSGI',
        'CAS_CODE',
        'IUR',
        'NAME_CN',
        'NAME_EN',
        'RFC',
        'RFDO',
      ]
      let physicalArr = [
        'CAS_CODE',
        'DA',
        'DW',
        'HSTRIKE',
        'KD',
        'KOC',
        'NAME_CN',
        'NAME_EN',
        'S',
      ]
      toxicity.map((columns: any, index: any) => {
        toxicityArr.map((str: any, index: any) => {
          if (!columns[str]) {
            columns[str] = '-'
          }
        })
      })
      physical.map((columns: any, index: any) => {
        physicalArr.map((str: any, index: any) => {
          if (!columns[str]) {
            columns[str] = '-'
          }
        })
      })
      console.log("base_info",base_info);
      
      this.setState({
        base_info,
        soil_pollutant,
        water_pollutant,
        all_param_value,
        physical,
        project_model,
        toxicity
      })
    }).catch(() => {

    })
  }
  handleRisk = (risk: any) => {
    console.log("risk", risk);

    let {
      control: {
        soil: soilControl,
        water: waterControl
      },
      risk: {
        report_water,
        soil: {
          cancer: soilCancer,
          not_cancer: soilNotCancer
        },
      },
      exposure: {
        soil: {
          cancer: exsoilCancer,
          not_cancer: exsoilNotCancer
        },
        water: {
          cancer: exwaterCancer,
          not_cancer: exwaterNotCancer
        }
      },
      contribution: {
        soil: {
          cancer: contributionsoil,
          not_cancer: contributionsoilNot
        },
        water: {
          cancer: contributionwater,
          not_cancer: contributionwaterNot
        },
        graphic: {
          soil: soilgraphic,
          water: watergraphic
        },
      },
    } = risk;
    let soilControlArr = [
      'HCVSdcs',
      'HCVSiiv1',
      'HCVSiov1',
      'HCVSiov2',
      'HCVSois',
      'HCVSpis',
      'RCVSdcs',
      'RCVSiiv1',
      'RCVSiov1',
      'RCVSiov2',
      'RCVSois',
      'RCVSpis',
      'feiZhiAiControlValue',
      'total',
      'zhiAiControlValue'
    ]
    let waterControlArr = [
      'HCVScgw',
      'HCVSiiv2',
      'HCVSiov3',
      'RCVScgw',
      'RCVSiiv2',
      'RCVSiov3',
      'feiZhiAiDXControlValue',
      'total',
      'zhiAiDXControlValue'
    ]
    let report_waterArr = [
      'CRcgw',
      'CRiiv2',
      'CRiov3',
      'HQcgw',
      'HQiiv2',
      'HQiov3',
      'feiZhiAiDXValue',
      'zhiAiDXValue'
    ]
    let soilCancerArr = [
      'CRdcs',
      'CRiiv1',
      'CRiov1',
      'CRiov2',
      'CRois',
      'CRpis',
      'zhiAiValue'
    ]
    let soilNotCancerArr = [
      'HQdcs',
      'HQiiv1',
      'HQiov1',
      'HQiov2',
      'HQois',
      'HQpis',
      'feiZhiAiValue'
    ]
    let exsoilCancerArr = [
      'OISERca',
      'DCSERca',
      'PIERca',
      'IOVERca1',
      'IOVERca2',
      'IIVERca1',
    ]
    let exsoilNotCancerArr = [
      'OISERnc',
      'DCSERnc',
      'PIERnc',
      'IOVERnc1',
      'IOVERnc2',
      'IIVERnc1',
    ]
    let exwaterCancerArr = [
      'IOVERca3',
      'IIVERca2',
      'CGWERca',
    ]
    let exwaterNotCancerArr = [
      'IOVERnc3',
      'IIVERnc2',
      'CGWERnc',
    ]
    let contributionsoilArr = [
      'DCS',
      'IIV1',
      'IOV1',
      'IOV2',
      'OIS',
      'PIS',
    ]
    let contributionsoilNotArr = [
      'OIS',
      'DCS',
      'PIS',
      'IOV1',
      'IOV2',
      'IIV1',
    ]
    let contributionwaterArr = [
      'CGW',
      'IIV2',
      'IOV3',
    ]
    let contributionwaterNotArr = [
      'CGW',
      'IIV2',
      'IOV3',
    ]

    let graphicArrcolumns = [
      { id: 1, cn: '径口摄入土壤',en:'OIS' },
      { id: 2, cn: '皮肤接触土壤颗粒物',en:'DCS' },
      { id: 3, cn: '吸入土壤颗粒物',en:'PIS' },
      { id: 4, cn: '吸入室外表层挥发性污染物',en:'IOV1' },
      { id: 5, cn: '吸入室外下层挥发性污染物',en:'IOV2' },
      { id: 6, cn: '吸入室内下层挥发性污染物',en:'IIV1' },
      { id: 7, cn: '饮用地下水',en:'CGW' },
      { id: 8, cn: '吸入室外地下水挥发性污染物蒸气',en:'IOV3' },
      { id: 9, cn: '吸入室内地下水挥发性污染物蒸气',en:'IIV2' },
    ]
    soilControl.map((columns: any, index: any) => {
      soilControlArr.map((str: any, index: any) => {
        if (columns[str] || columns[str] == '0') {
          columns[str] = columns[str].toExponential(2)
        } else {
          columns[str] = '-'
        }
      })
    })
    waterControl.map((columns: any, index: any) => {
      waterControlArr.map((str: any, index: any) => {
        if (columns[str] || columns[str] == '0') {
          columns[str] = columns[str].toExponential(2)
        } else {
          columns[str] = '-'
        }
      })
    })
    report_water.map((columns: any, index: any) => {
      report_waterArr.map((str: any, index: any) => {
        if (columns[str] || columns[str] == '0') {
          columns[str] = columns[str].toExponential(2)
        } else {
          columns[str] = '-'
        }
      })
    })
    soilCancer.map((columns: any, index: any) => {
      soilCancerArr.map((str: any, index: any) => {
        if (columns[str] || columns[str] == '0') {
          columns[str] = columns[str].toExponential(2)
        } else {
          columns[str] = '-'
        }
      })
    })
    soilNotCancer.map((columns: any, index: any) => {
      soilNotCancerArr.map((str: any, index: any) => {
        if (columns[str] || columns[str] == '0') {
          columns[str] = columns[str].toExponential(2)
        } else {
          columns[str] = '-'
        }
      })
    })
    exsoilCancer.map((columns: any, index: any) => {
      exsoilCancerArr.map((str: any, index: any) => {
        if (columns[str] || columns[str] == '0') {
          columns[str] = columns[str].toExponential(2)
        } else {
          columns[str] = '-'
        }
      })
    })
    exsoilNotCancer.map((columns: any, index: any) => {
      exsoilNotCancerArr.map((str: any, index: any) => {
        if (columns[str] || columns[str] == '0') {
          columns[str] = columns[str].toExponential(2)
        } else {
          columns[str] = '-'
        }
      })
    })
    exwaterCancer.map((columns: any, index: any) => {
      exwaterCancerArr.map((str: any, index: any) => {
        if (columns[str] || columns[str] == '0') {
          columns[str] = columns[str].toExponential(2)
        } else {
          columns[str] = '-'
        }
      })
    })
    exwaterNotCancer.map((columns: any, index: any) => {

      exwaterNotCancerArr.map((str: any, index: any) => {
        if (columns[str] || columns[str] == '0') {
          columns[str] = columns[str].toExponential(2)
        } else {
          columns[str] = '-'
        }
      })
    })
    for (let i = 0; i < contributionsoil.length; i++) {
      let arr = Object.keys(contributionsoil[i].data);
      if (arr.length == 0) {
        contributionsoil.splice(i--, 1)
      }
      contributionsoil.map((columns: any, index: any) => {
        let obj3 = Object.assign(columns, columns.data);
        obj3.total = "100%"
        contributionsoilArr.map((str: any, index: any) => {
          columns[str] = ((columns[str] * 100).toFixed(2) + "%")
          if (columns[str] == '0.00%') {
            columns[str] = "0%"
          } if (columns[str] == '100.00%') {
            columns[str] = "100%"
          }
        })
      })
    }
    for (let i = 0; i < contributionsoilNot.length; i++) {
      let arr = Object.keys(contributionsoilNot[i].data);
      if (arr.length == 0) {
        contributionsoilNot.splice(i--, 1)
      }
      contributionsoilNot.map((columns: any, index: any) => {
        let obj3 = Object.assign(columns, columns.data);
        obj3.total = "100%"
        contributionsoilNotArr.map((str: any, index: any) => {
          columns[str] = ((columns[str] * 100).toFixed(2) + "%")
          if (columns[str] == '0.00%') {
            columns[str] = "0%"
          } if (columns[str] == '100.00%') {
            columns[str] = "100%"
          }
        })
      })
    }
    for (let i = 0; i < contributionwater.length; i++) {
      let arr = Object.keys(contributionwater[i].data);
      if (arr.length == 0) {
        contributionwater.splice(i--, 1)
      }
      contributionwater.map((columns: any, index: any) => {
        let obj3 = Object.assign(columns, columns.data);
        obj3.total = "100%"
        contributionwaterArr.map((str: any, index: any) => {
          columns[str] = ((columns[str] * 100).toFixed(2) + "%")
          if (columns[str] == '0.00%') {
            columns[str] = "0%"
          }
          if (columns[str] == '100.00%') {
            columns[str] = "100%"
          }
          if (columns[str] == '0') {
            columns[str] = "0%"
          }
          if (columns[str] == '1') {
            columns[str] = "100%"
          }
        })
      })


    }
    for (let i = 0; i < contributionwaterNot.length; i++) {
      let arr = Object.keys(contributionwaterNot[i].data);
      if (arr.length == 0) {
        contributionwaterNot.splice(i--, 1)
      }
      contributionwaterNot.map((columns: any, index: any) => {
        let obj3 = Object.assign(columns, columns.data);
        obj3.total = "100%"
        contributionwaterNotArr.map((str: any, index: any) => {
          columns[str] = ((columns[str] * 100).toFixed(2) + "%")
          if (columns[str] == '0.00%') {
            columns[str] = "0%"
          } if (columns[str] == '100.00%') {
            columns[str] = "100%"
          }
        })
      })
    }
    let graphicArr = Object.assign(soilgraphic, watergraphic);
    graphicArrcolumns.map((item: any, index: any) => {
      for (const i in graphicArr) {
        if (graphicArr[i]==true) {
          graphicArr[i]=<div className="graphic_Outlined">
          <CheckOutlined />
        </div>
        }
        if (item.en==i) {
          return Object.assign(item, {url:graphicArr[i]} );
        }
      }
    })
    graphicArr=graphicArrcolumns
    console.log("graphicArr",graphicArr);

    const token=getToken()
    const SessionProductId=getSessionProductId()
    const User=getUser()
    const SessionLevel=getSessionLevel()
    console.log("token",token);
    console.log("SessionProductId",SessionProductId);
    console.log("User",User);
    console.log("SessionLevel",SessionLevel);
    
    this.setState({
      soilControl,
      waterControl,
      soilCancer,
      soilNotCancer,
      exsoilCancer,
      exsoilNotCancer,
      exwaterCancer,
      exwaterNotCancer,
      contributionsoil,
      contributionsoilNot,
      contributionwater,
      contributionwaterNot,
      graphicArr,
      report_water,
      token,
      SessionProductId,
      User,
    })
  }
  render() {
    return (
      <div className="report">
        {
          this.state.level == '1' ? (
            <FirstLevel project_id={this.state.project_id} base_info={this.state.base_info} soil_pollutant={this.state.soil_pollutant} water_pollutant={this.state.water_pollutant} props={this.props}></FirstLevel>
          ) : ''
        }
        {
          this.state.level == '2' ?
            (
              <TwoLevel
                report_water={this.state.report_water}
                soilNotCancer={this.state.soilNotCancer}
                waterControl={this.state.waterControl}
                soilControl={this.state.soilControl}
                soilCancer={this.state.soilCancer}
                toxicity={this.state.toxicity}
                physical={this.state.physical}
                project_model={this.state.project_model}
                base_info={this.state.base_info}
                soil_pollutant={this.state.soil_pollutant}
                water_pollutant={this.state.water_pollutant}
                all_param_value={this.state.all_param_value}
                exsoilCancer={this.state.exsoilCancer}
                exsoilNotCancer={this.state.exsoilNotCancer}
                exwaterCancer={this.state.exwaterCancer}
                exwaterNotCancer={this.state.exwaterNotCancer}
                contributionsoil={this.state.contributionsoil}
                contributionsoilNot={this.state.contributionsoilNot}
                contributionwater={this.state.contributionwater}
                contributionwaterNot={this.state.contributionwaterNot}
                graphicArr={this.state.graphicArr}
                project_id={this.state.project_id}
                props={this.props}
                token={this.state.token}
                SessionProductId={this.state.SessionProductId}
                User={this.state.User}
              >


              </TwoLevel>
            ) : ''
        }

      </div>
    );
  }
}

export default generalReport;
