import React, { useEffect, useState } from "react";
import { Form, Input, Radio, DatePicker, Button,Row,Col,Tooltip,Select,message ,Spin} from "antd";
import provinces from '../assets/database/provinces.json'
import cities from '../assets/database/province-city.json'
import {createProject,getProjectInfo,updateProject} from "../apis/common"
import moment from 'moment'
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN'
import ProvinceAndCityCascader from "./common/ProvinceAndCity.jsx"
import {saveProductId,checkProductd} from '../configs/checkProductId.js'
import '../../src/assets/styles/common.less'
import first from "../assets/images/init/first-inactive.png"
import second from "../assets/images/init/second-inactive.png"
import {getSessionProductId} from '../utils/token'
import firsthover from "../assets/images/init/first-hover.png"
import secondhover from "../assets/images/init/second-hover.png"

import firstactive from "../assets/images/init/first-active.png"
import secondactive from "../assets/images/init/second-active.png"
import { withRouter,useHistory } from "react-router-dom";

const provinceData = provinces;
const cityData = cities;

const BasicInfoForm = () => {
  let history = useHistory();
  const [form] = Form.useForm();
  const [firstImage,setFirstImage] = useState(first)
  const [project_time,setProjectTime] = useState(new Date())

  const [twoImage,setTwoImage] = useState(second)
  const [isclick,setIsclick] = useState(false)
  const leftitle = '包括城市建设用地中的居住用地、公共管理与公共服务用地中的中小学用地、医疗卫生用地和社会福利设施用地以及公园绿地中的社区公园或公园工地等。'
  const righttitle= '包括城市建设用地中的工业用地、物流仓储用地、商业服务业设施用地、道路与交通设施用地、公用设施用地、公共管理与公共服务用地、以及绿地与广场等。'
  const [cities, setCities] = React.useState(cityData[provinceData[0]["name"]]);
  const [province, setProvince] = React.useState(provinceData[0]["name"]);
  const [secondCity, setSecondCity] = React.useState(cityData[provinceData[0]["name"]][0]);
  const [projectId, setProjectId] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  // loading
  const handleProvinceChange = (value,city) => {
    setProvince(value)
    setCities(cityData[value]);
    setSecondCity(cityData[value][0]);
    if(typeof(city)=='string'){
      form.setFieldsValue({ 
        city:city
      });
    }else{
      form.setFieldsValue({ 
        city:cityData[value][0]
      });
    }
  };
  useEffect(() => {
    let project_id =getSessionProductId();
    if(project_id){
      setProjectId(project_id)
      getProjectInfoData(project_id)
    }else{
      setLoading(false)
    }
  }, []);

  const onSecondCityChange = value => {
    setSecondCity(value);
  };
  const onFinish = async (values) => {
    if(projectId){
      updatePro(values)
    }else{
      createPro(values)
    }
  };

  const updatePro = async (values) =>{
    let {
      name,
      type,
      address,
      time
    }=values;
    // console.log(new Date(time._d).getTime()/1000)
    console.log("time",time);
    let newtime= parseInt((new Date(time._d).getTime())/1000)
    await updateProject({    
      project_name:name,
      project_type:type,
      project_time:newtime,
      province:province,
      city:secondCity,
      address,
      id:projectId
    }).then((res)=>{
      history.push({
        pathname:"/project/analysis"
      })
    }).catch((err)=>{
      message.warn('服务器异常，请稍后再试')
    })
  }

  const createPro = async(values)=>{
    let {
      name,
      type,
      address,
      time
    }=values;
    console.log("time",time);

    let newtime= parseInt((new Date(time._d).getTime())/1000)
    await createProject({    //有
      project_name:name,
      project_type:type,
      project_time:newtime,
      province:province,
      city:secondCity,
      address
    }).then((res)=>{
      saveProductId(res.data.project_id)
      history.push({
        pathname:"/project/analysis"
      }) 
    }).catch((err)=>{
      message.warn('服务器异常，请稍后再试')
    })
  }
   const getProjectInfoData = async(project_id)=>{
    let res =  await getProjectInfo({project_id});
    try {
      let data = res.data;
      let {
        address,
        city,
        project_name,
        project_time,
        project_type,
        province
      }= data;
      let time= moment(moment(parseInt(project_time)*1000).format())
      form.setFieldsValue({ 
        name:project_name,
        type:project_type,
        time:time,
        address,
        province,
        city
      });
      handleProvinceChange(province,city)
      setLoading(false)
      let value =project_type=='1'?'one':'two'
      clickleft(value)
    } catch (error) {
      
    }
   }

  const disabledDate = ( current )=>{
    return current > moment().endOf('day')
  }
  const clickleft = (value)=>{
     if(value == 'one'){
      setFirstImage(firstactive)
      setTwoImage(second);
      form.setFieldsValue({ type: '1' });
     }else{
      setFirstImage(first)
      setTwoImage(secondactive)
      form.setFieldsValue({ type: '2' });
     }
     setIsclick(true)
  }
  const mouse = (value,active)=>{
    if(isclick){
      return
    }
    if(active=='enter'){
      if(value == 'one'){
        setFirstImage(firsthover)
        setTwoImage(second)
       }else{
        setFirstImage(first)
        setTwoImage(secondhover)
       }
    }else{
      setFirstImage(first)
      setTwoImage(second)
    }
 }
 const printInput = (i)=>{
  if(i.target.value.length>0){
    form.setFieldsValue({ address: i.target.value });
  }else{
    form.setFieldsValue({ address: '' });
  }
 }
  return (
    <div className="basic-info">
    <Spin spinning={loading}>
    <Row>
      <Col  xs={0} sm={0} md={7} lg={7} xl={7}></Col>
      <Col  xs={24} sm={24} md={10} lg={10} xl={10}>
        <Form name="basic-info" onFinish={onFinish} form={form}
         initialValues={
          {
            province:province,
            city:secondCity
          }
         }
        >
          
          <Form.Item
            label="项目名称"
            name="name"
            rules={[
              {
                required: true,
                message: "请输入项目名称",
              },
            ]}
          >
            <Input autoComplete="off"/>
          </Form.Item>

          <Form.Item
            label="用地类型"
            name="type"
            rules={[
              {
                required: true,
                message: "请选择用地类型",
              },
            ]}
          >
            <Row gutter={0}>
              <Col span="12">
              <Tooltip placement="top" title={leftitle}>
                <img src={firstImage} onClick={() => clickleft('one')} onMouseEnter={() => mouse('one','enter')} onMouseLeave={() => mouse('one','leave')}></img>
              </Tooltip>
                
              </Col>
              <Col span="12">
                <Tooltip placement="top" title={righttitle}>
                  <img src={twoImage} onClick={()=>clickleft('two')} onMouseEnter={() => mouse('two','enter')} onMouseLeave={() => mouse('two','leave')}></img>
                </Tooltip>
              </Col>
            </Row>
          </Form.Item>

            <Form.Item label="评估时间" name="time" rules={[{
                required:true, message:"请选择评估时间"
            }]}> 
                <DatePicker disabledDate={disabledDate} locale={locale} />
            </Form.Item>

            <Form.Item label="项目地点" name="address" className="projectform">
            <Row>
              <Col span={11}>
                <Form.Item name="province">
                  <Select  onChange={handleProvinceChange}>
                      {provinceData.map(province => (
                        <Select.Option key={province["name"]}>{province["name"]}</Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={11} offset={2}>
              <Form.Item name="city">
                        <Select value={secondCity} onChange={onSecondCityChange}>
                          {cities.map(city => (
                            <Select.Option key={city}>{city}</Select.Option>
                          ))
                          } 
                        </Select>
                    </Form.Item>
              </Col>
            </Row>
                    
                    <Form.Item name="address" rules={[{
                required:true, message:"请输入项目地点"
            }]}>
                      <Input onChange={printInput} onPressEnter={printInput} autoComplete="off"/>
                    </Form.Item>
            </Form.Item>
            <Row gutter={0}>
              <Col span="24" offset="10">
                <Form.Item>
                  <button className="formbtn">下一步</button>
                </Form.Item>
              </Col>
            </Row>
        </Form>
      </Col>
      <Col  xs={0} sm={0} md={7} lg={7} xl={7}></Col>
    </Row>
    </Spin>
    </div>
  );
};

export default BasicInfoForm;
