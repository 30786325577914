import './followPollute.less'
import React, { useContext, useState, useEffect, useRef } from 'react';
import  PopEditData  from '../../../components/common/popEditData'
import { Table, Input, Button, Popconfirm, Form ,Checkbox,Col,Row,Modal, message,Spin,Radio,Upload} from 'antd';
import { FormInstance } from 'antd/lib/form';
import {getProjectPollutant,deleteBindPollutant,differBackgroundValue,differDepth,differFilterGroundWater,savePollutantValue,getProjectInfo} from "../../../apis/common";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {checkProductd} from '../../../configs/checkProductId.js'
import { getToken,getSessionProductId } from '../../../utils/token';
// const [modal, contextHolder] = Modal.useModal();
const EditableContext = React.createContext<FormInstance<any> | null>(null);
const { confirm } = Modal;
interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  history:any;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<Input>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
console.log("values",values.soil_concentration);
console.log("values",values.ground_concentration);

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  key: React.Key;
  NAME_CN: string;
  NAME_EN: string;
  CAS_CODE: string;
  BIAOZHUN: string;
  TURANG:string;
}

interface EditableTableState {
  dataSource: any;
  loading:boolean;
  selectedRowKeys:any;
  selectRowIds:any;
  level:any;
  count: number;
  isShowtable:boolean,
  child:any,
  Option:any,
  checkdefaultValue:any;
  radiodefaultValue:any;
  columns:any,
  project_id:string
  areaRegion: string,
  sheet: string,

}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;
interface IProps {
  login: any;
  register: any;
  history: any;
  retrieve: any;
  location:any;
}
class followPollute extends React.Component<IProps, EditableTableState> {
  columns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[];
  PopEditData:any;

  constructor(props: any) {
    super(props);
    // this.PopEditData = React.createRef();    
    this.columns = [
      {
        title: '中文名',
        dataIndex: 'name_cn'
      },
      {
        title: '英文名',
        dataIndex: 'name_en',
      },
      {
        title: 'CAS编号',
        dataIndex: 'cas_code',
      },
      {
        title: '是否标准库',
        dataIndex: 'is_standard',
      }
    ];
    // this.popdata = React.createRef()
    this.state = {
      dataSource: [],
      level:'',
      selectedRowKeys:[],
      selectRowIds:'',
      count: 100,
      isShowtable:true,
      loading:true,
      child:'',
      project_id:'',
      areaRegion: '',
      sheet:'0',
      Option:[{
        label: '区分地表土壤和地下土壤',
        value: 1
      },{
        label: '输入背景值',
        value: 2
      }],
      checkdefaultValue:[],
      radiodefaultValue:3,
      columns:[
        {
          title: '中文名',
          dataIndex: 'name_cn'
        },
        {
          title: '英文名',
          dataIndex: 'name_en',
        },
        {
          title: 'CAS编号',
          dataIndex: 'cas_code',
        },
        {
          title: '是否标准库',
          dataIndex: 'is_standard',
        },
        {
          title: '土壤含量（mg.kg-1）',
          dataIndex: 'soil_concentration',
          editable: true,
          className: 'follow-class',
        },
        {
          title: '地下水浓度（mg.L-1)',
          dataIndex: 'ground_concentration',
          editable: true,
          className: 'follow-class',
        }
      ]
    };
  }

  // ['区分地表土壤和地下土壤','输入背景值','地下水筛选IV类']
   componentDidMount(){
    let productId=checkProductd();
    if(productId){
      this.setState({
        project_id:productId
      })
      this.getPollutantData(productId)
      this.getProjectInfoData(productId)
    }
  }
  getPollutantData =async(project_id?:any)=>{
    await getProjectPollutant({    
      page:1,
      size:10000,
      project_id:project_id || this.state.project_id
    }).then((res:any)=>{
      let data = res.data;
      if(data.length>0){
        data.forEach((element:any,i:any) => {
          element.key=i;
          if(element.is_standard==1){
            element.is_standard ='是'
          }else{
            element.is_standard ='否'
          }
        });
        this.setState({
          dataSource:data,
          isShowtable:true,
          selectedRowKeys:'',
          selectRowIds:'',
          loading:false
        })
      }else{
        this.setState({
          isShowtable:false,
          loading:false
        })
      }
    }).catch((err:any)=>{
      // message.warn('服务器异常，请稍后再试')
    })
  }
  handleDelete = async() => {
    let selectRowIds = this.state.selectRowIds;
    await deleteBindPollutant({    
      delete_ids:selectRowIds,
      project_id:this.state.project_id
    }).then((res:any)=>{
      this.getPollutantData()
    }).catch((err:any)=>{
      message.warn('服务器异常，请稍后再试')
    })
  };

  handleSave = (row: DataType) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });
  };
  deleteline = ()=>{
    let that= this
    if(!this.state.selectRowIds){
      message.info('至少选择一项')
      return
    }
    confirm({
      title: '删除',
      icon:<ExclamationCircleOutlined />,
      content: '是否删除选中的污染物？',
      okText: '确定',
      okType: 'primary',
      centered:true,
      okButtonProps: {
        
      },
      cancelText: '取消',
      onOk() {
        that.handleDelete()
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  onSelectChange = (selectedRowKeys:any,selectRowIds:any) => {
    let str = "";
    selectRowIds.forEach((element:any) => {
      str += element.id + ','
    });
    if (str.length > 0) {
      str = str.substr(0,str.length - 1);
     } 
    this.setState({ 
      selectedRowKeys,
      selectRowIds:str
     });
  };
 
    // 父组件触发子组件的事件
    triggerEvevt = () => {
      this.PopEditData.setModal1Visible(true)
    };
    componentWillUnmount(){
      this.setState({
        checkdefaultValue:[]
      })
      
    }
    checkbox(value:any){
      console.log(value)
      this.setState({
        checkdefaultValue:value
      })
      let soil_concentration ={
          title: '土壤含量（mg.kg-1）',
          dataIndex: 'soil_concentration',
          editable: true,
          className: 'follow-class',
      }
      let ground_concentration={
        title: '地下水浓度（mg.L-1)',
        dataIndex: 'ground_concentration',
        editable: true,
        className: 'follow-class',
      }
      let soil_surface_concentration={
        title: '地表土壤含量（mg.kg-1)',
        dataIndex: 'soil_surface_concentration',
        editable: true,
        className: 'follow-class',
      }
      let soil_bottom_concentration={
        title: '下层土壤含量（mg.kg-1)',
        dataIndex: 'soil_bottom_concentration',
        editable: true,
        className: 'follow-class',
      }
      let soil_background_value={
        title: '土壤背景值（mg.kg-1)',
        dataIndex: 'soil_background_value',
        editable: true,
        className: 'follow-class',
      }
      let groundwater_background_value={
        title: '地下水背景值（mg.kg-1)',
        dataIndex: 'groundwater_background_value',
        editable: true,
        className: 'follow-class',
      }
      let soil_surface_background_value={
        title: '地表土壤背景值（mg.kg-1)',
        dataIndex: 'soil_surface_background_value',
        editable: true,
        className: 'follow-class',
      }
      let soil_bottom_background_value={
        title: '下层土壤背景值（mg.kg-1)',
        dataIndex: 'soil_bottom_background_value',
        editable: true,
        className: 'follow-class',
      }
      let seelctArr= new Set(value);
      let temp1= seelctArr.has(1);
      let temp2= seelctArr.has(2);
      let temp3= seelctArr.has(3);

      let concatrr = []
      if(temp1&&temp2){
        concatrr.push(soil_surface_concentration,soil_surface_background_value,soil_bottom_concentration,soil_bottom_background_value,ground_concentration,groundwater_background_value) 
      }else if(temp1){
        concatrr.push(soil_surface_concentration,soil_bottom_concentration,ground_concentration) 
      }else if(temp2){
        concatrr.push(soil_concentration,soil_background_value,ground_concentration,groundwater_background_value) 
      }else{
        concatrr.push(soil_concentration,ground_concentration)
      }
      concatrr = this.columns.concat(concatrr);
      this.setState({
        columns:concatrr
      })
      this.getPollutantData();
      
      this.postDifferDepth(temp1)
      this.postDifferBackgroundValue(temp2)
      // this.postDifferGroundWater(temp3)
      // this.clearInputContent()
    }
    checkRadio(values:any){
      console.log(values,'====1')
      let {
        target:{
          value
        }
      }=values
      console.log(value,'====')
      if(value =='3'){
        this.postDifferGroundWater(false)
      }else{
        this.postDifferGroundWater(true)
      }
      this.setState({
        radiodefaultValue:value
      })
    }
    clearInputContent= () => {
      const newData = [...this.state.dataSource];
      newData.forEach((ele,i)=>{
        newData[i]['ground_concentration']= '0'
        newData[i]['groundwater_background_value']= '0'
        newData[i]['soil_background_value']= '0'
        newData[i]['soil_bottom_background_value']= '0'
        newData[i]['soil_bottom_concentration']= '0'
        newData[i]['soil_concentration']= '0'
        newData[i]['soil_surface_background_value']= '0'
        newData[i]['soil_surface_concentration']= '0'
      })
      this.setState({ dataSource: newData });
    };
    //是否勾选输入背景值接口
    postDifferBackgroundValue(value:any){
      differBackgroundValue({
        is_background_value:Number(value),
        id:this.state.project_id
      }).then(()=>{

      }).catch(()=>{

      })
    }
    // 更改是否区分地表土壤和下层土壤接口
    postDifferDepth(value:any){
      console.log("value",value);
      if (value==true) {
        console.log("是true",value);
        this.setState({
          sheet:'1'
        })
      }else{
        console.log("是fa",value);
        this.setState({
          sheet:'0'
        })
      }
      differDepth({
        is_differ_soil:Number(value),
        id:this.state.project_id
      }).then(()=>{
        
      }).catch(()=>{
        
      })
    }
    // 是否勾选地下水筛选值4类接口
    async postDifferGroundWater(value:any){
      await differFilterGroundWater({
        is_filter_groundwater:Number(value),
        id:this.state.project_id
      }).then(()=>{

      }).catch(()=>{
        
      })
    }

    gotoTap= async(step:string)=>{
      this.state.dataSource.forEach((item:any )=> {
        console.log("item",item.ground_concentration,item.soil_concentration,item);
        
      for (const key in item) {
        console.log("key",key,item[key],item[key]);
        
       }
      });

      let level =this.state.level;
      if(step == 'back'){
        this.props.history.push({
          pathname:"/project/analysis"
        })
        return
      }
      console.log("JSON.stringify(this.state.dataSource)",this.state.dataSource,JSON.stringify(this.state.dataSource))

      await savePollutantValue({    
        data:JSON.stringify(this.state.dataSource)
      }).then((res:any)=>{
         if(level=='1'){
           this.props.history.push({
              pathname:"/project/generalReport"
            })
         }else if(level=='2'){
           this.props.history.push({
              pathname:"/project/exposeRoad"
          })
         }
      }).catch((err:any)=>{
        message.warn('服务器异常，请稍后再试')
      })
    }
    canPostData=() =>{
      
    }
    getProjectInfoData = async(project_id:any)=>{
      let res =  await getProjectInfo({project_id});
      let {
        is_background_value,
        is_differ_soil,
        is_filter_groundwater,
        level
      }=res.data
      this.setState({
        level
      })
      let beforeCheck = [];
      let radioCheck = 0;
      if(Boolean(is_differ_soil)){
        beforeCheck.push(1)
      }
      if(Boolean(is_background_value)){
        beforeCheck.push(2)
      }
      if(Boolean(is_filter_groundwater)){
        radioCheck= 4
      }else{
        radioCheck= 3
      }
      if(radioCheck>0){
        let values = {
          target:{
            value:radioCheck
          }
        }
        this.checkRadio(values)
      }
      if(beforeCheck.length>0){
        this.checkbox(beforeCheck)
      }
     }
     cleaDatas = ()=>{
      let that= this
      confirm({
        title: '一键清零',
        icon:<ExclamationCircleOutlined />,
        content: '是否要清空所有的污染物参数',
        okText: '确定',
        okType: 'primary',
        centered:true,
        okButtonProps: {
          
        },
        cancelText: '取消',
        onOk() {
          that.cleaData()
        },
        onCancel() {
          console.log('Cancel');
        },
      });

     }
     cleaData= async()=>{
      let data = this.state.dataSource;
      data.forEach((ele:any) => {
        ele.ground_concentration = 0
        ele.groundwater_background_value = 0
        ele.soil_background_value = 0
        ele.soil_bottom_background_value = 0
        ele.soil_bottom_concentration = 0
        ele.soil_concentration = 0
        ele.soil_surface_background_value = 0
        ele.soil_surface_concentration = 0
      });
      await savePollutantValue({    
        data:JSON.stringify(data)
      }).then((res:any)=>{
        this.getPollutantData()
      }).catch((err:any)=>{
      })
     }
     download = () => {
    };
    uploadChange = (info: any) => {
      console.log("info", info);
  
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} 已上传成功`);
        this.getPollutantData()
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  render() {
    const { dataSource,selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange:this.onSelectChange
    };

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const columns = this.state.columns.map((col:any )=> {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: DataType) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    const draggerProps = {
      name: 'file',
      accept: '.csv,.xls,.xlsx',
      action: "https://api.crisk.com.cn/api/v1/project/savePollutantValueExcel"+'?'+'project_id='+`${getSessionProductId()}`+'&'+'sheet='+`${this.state.sheet}`,
      data: (file:any,info:any)=>{
        console.log("file",file,info);
        
      },
      // action:`${createApi}/pollution/survey/importExcel`,
      headers: {
        token: getToken(),
      },
      onChange: this.uploadChange,
    };
    return (
      <div>
        <Spin spinning={this.state.loading}>
        {this.state.isShowtable?(
        <div>
          <div className="Checkbox">
            <div className="CheckboxGroup">
              <Checkbox.Group onChange={this.checkbox.bind(this)} options={this.state.Option} value={this.state.checkdefaultValue}>
              </Checkbox.Group>
              <Radio.Group className="RadioGroup" onChange={this.checkRadio.bind(this)} value={this.state.radiodefaultValue}>
                <Radio value={3}>地下水筛选III类</Radio>
                <Radio value={4}>地下水筛选IV类</Radio>
              </Radio.Group>
          
            </div>
          

          </div>
          <div className="followbtn">
            <button className="addbtn" onClick={this.triggerEvevt}>+添加</button>
            <button className="delatebtn" onClick={this.deleteline.bind(this)}>删除</button>
            <button className="followdanger" onClick={this.cleaDatas.bind(this)}>一键清零</button>
           
          </div>
          <div className="checkboxred">
              *表格中红色表头下方是可编辑内容，需用户手动填写，否则为默认数据
          </div>
          <Table
              components={components}
              rowSelection={rowSelection}
              bordered
              loading={!this.state.isShowtable}
              dataSource={dataSource}
              columns={columns as ColumnTypes}
              className='antd_table_thead'
          ></Table>
          <div className="exposebtn">
            <button className="formbtnleft" onClick={this.gotoTap.bind(this,'back')}>上一步</button>
            <button className="formbtnright" onClick={this.gotoTap.bind(this,'next')}>下一步</button>
          </div>
        </div>):
        (
        <div className="empty">
          <img src={require('../../../assets/images/followPollute/empty.png').default} className="emptyimg"></img>
          <div className="emptytext">暂无关注污染物</div>
          <div className='addUpload_box'>
                  <button className="addbtn" onClick={this.triggerEvevt}>+添加</button>
                  <Upload className='add_Upload' maxCount={1} {...draggerProps} >
                    <Button className="addbtnUpload" >导入数据</Button>
                  </Upload>
                  <button className="addbtn download_btn" onClick={this.download}><a className="download" href="https://crisk.oss-cn-shanghai.aliyuncs.com/Report/referenceTemplate.xlsx">下载模板</a></button>
                </div>
        </div>
        )}
        
        <PopEditData triggerRef={(ref:any)=>this.PopEditData=ref} downProps={this.getPollutantData}></PopEditData>
        </Spin>
      </div>
    );
  }
}
export default followPollute;