import { Select } from 'antd';

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
// import { Interface } from 'readline';
import "../../assets/styles/bread.less";
import { businessRouteList} from '../../router/utils';


import bread1 from "../../assets/images/breadcrumb/bread1.png"
import bread2 from "../../assets/images/breadcrumb/bread2.png"
import bread3 from "../../assets/images/breadcrumb/bread3.png"
import bread4 from "../../assets/images/breadcrumb/bread4.png"
//导航面包屑组件
// export default BreadCrumb;
// interface Iprops{

// }
interface IProps {
  history:any
}

interface IState {
  title:any,
  iconSrc:any,
}
const urlpatterns = [
  {
    url:'/project/info',
    keys:'0',
    breadtitle:'项目信息',
    id:1
  },
  {
    url:'/project/analysis',
    keys:'1',
    breadtitle:'层次分析',
    id:2
  },
  {
    url:'/project/followPollute',
    keys:'2-1',
    breadtitle:'项目输入/关注污染物',
    id:3
  },
  {
    url:'/project/exposeRoad',
    keys:'2-2',
    breadtitle:'项目输入/暴露途径及模型',
    id:3
  },
  {
    url: '/project/parmas',
    keys:'2-3',
    breadtitle:'项目输入/参数设置',
    id:3
  },
  {
    url:'/project/conceptModel',
    keys:'3-1',
    breadtitle:'项目输出/概念模型',
    id:4
  },
  {
    url:'/project/polluteParamsTable',
    keys:'3-2',
    breadtitle:'项目输出/污染物参数表',
    id:4
  },
  {
    url: '/project/',
    keys:'3-3',
    breadtitle:'项目输出/模型及参数表',
    id:4
  },
  
  {
    url: '/project/exposedQuantityTable',
    keys:'3-4',
    breadtitle:'项目输出/暴露量计算',
    id:4
  },
  {
    url:'/project/riskCalculation',
    keys:'3-5',
    breadtitle:'项目输出/风险计算',
    id:4
  },
  {
    url: '/project/ContributionRateCalculation',
    keys:'3-6',
    breadtitle:'项目输出/贡献率计算',
    id:4
  },
  {
    url: '/project/controlValueCalculation',
    keys:'3-7',
    breadtitle:'项目输出/控制值计算',
    id:4
  },
  {
    url:'/project/generalReport',
    keys:'3-8',
    breadtitle:'项目输出/总报告',
    id:4
  }


]
class BreadCrumb extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      title: '',
      iconSrc:''
    };
    this.props.history.listen((route:any) => {
      this.handleSelectTab(route)
    });
  }
  componentWillMount(){
    let route =this.props.history.location;
    this.handleSelectTab(route)
  }
  handleSelectTab=(route:any)=>{
    urlpatterns.map((value,key)=>{
      if(value.url==route.pathname){
       this.updateTitle(value)
      }
    })
  }
  updateTitle = (value:any) => {
    this.setState({
      title:value.breadtitle,
    })
    switch (value.id) {
      case 1:
        this.setState({
          iconSrc:bread1
        })
        break;
      case 2:
        this.setState({
          iconSrc:bread2
        })
          break;
      case 3:
        this.setState({
          iconSrc:bread3
        })
        break;
      case 4:
        this.setState({
          iconSrc:bread4
        })
        break;
      default:
        this.setState({
          iconSrc:bread1
        })
        break;
    }
  }
  render() {
    return (
      <div className="bread">
          <img src={this.state.iconSrc} className="breadImage"></img>
          <div className="breadText">{this.state.title}</div>
      </div>
    )
  }
}

export default withRouter(BreadCrumb);