import React, { useEffect, useState } from "react";
import { Form, Input, Checkbox, Button,message,Modal } from "antd";
import { getVerifyCode ,register} from "../apis/common";
import { withRouter,useHistory } from "react-router-dom";
//TODO: 页面跳转保留数据

let timeChange:any;

const RegistrationForm = (props:any) => {
  let history = useHistory();
  const [form] = Form.useForm();
  const [time, setTime] = useState(60);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showRead, confirmRead] = useState(false);
  const [showReadagree, Readagree] = useState(false);
  const [btnContent, setBtnContent] = useState("获取验证码");
  const [phoneNumber,setPhoneNumber] = useState('');
  // 注册登陆
  const onFinish = async (values: any) => {
    if(!showRead){
      message.info('请勾选服务协议');
      return
    }
    let {
      captcha,
      name,
      password,
      phone
    } =values;
    await register({      
      "account": name,
      "password": password,
      "verifyCode": captcha,
      "phone": phone
    }).then((res:any)=>{
      message.success('恭喜您，注册成功！')
      setTimeout(()=>{
        history.push("/login");
      },500)
      
    }).catch((err:any)=>{
      message.warn('服务器异常，请稍后再试')
    })
  };
  useEffect(()=>{
    clearInterval(timeChange);
    return ()=> clearInterval(timeChange)
  }, []);


  useEffect(()=>{
    if (time > 0 && time < 60){
      setBtnContent(`${time}s`)
    }else{
      clearInterval(timeChange);
      setBtnDisabled(false);
      setTime(60);
      setBtnContent('获取验证码')
    }
  }, [time])
  // 获取用户手机号
  const getPhone = (event:any ) =>{
    console.log(event.target.value)
    if(event.target.value){
      setPhoneNumber(event.target.value)
    }else{
      setPhoneNumber('')
    }
  }
  // 获取手机验证码
  const getCaptcha = async() =>{
    if(!phoneNumber){
      message.warning('请先输入手机号');
      return
    }
    await getVerifyCode({      
      "phone":phoneNumber,
      "dev":0,
      "type":1
    }).then((res:any)=>{
      timeChange = setInterval(()=> setTime(t => --t), 1000);
      setBtnDisabled(true)
      message.success('发送成功');
    }).catch((err:any)=>{
    })
  }
  const agreeMent =(agree:any)=>{
    if(agree){
      Readagree(false)
      confirmRead(true)
    }else{
      Readagree(false)
      confirmRead(false)
    }
  }
  return (
    <div className="register-form-container win-no-drag">
      <Form
        form={form}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        
      >
        <Form.Item
          name="name"
          label="用户名"
          rules={[
            {
              type: "string",
              message: "请输入用户名",
            },
            {
              required: true,
              message: "请输入用户名",
            },
          ]}
        >
          <Input placeholder="请输入用户名" autoComplete="off" />
        </Form.Item>

        <Form.Item
          name="phone"
          label="手机号"
          rules={[
            {
              required: true,
              message: "请输入正确手机号",
              len:11
            },
          ]}
        >
          <Input placeholder="请输入手机号" autoComplete="off" maxLength={11} onChange={getPhone}/>
        </Form.Item>
        <Form.Item label="验证码">
            <Form.Item
              name="captcha"
              noStyle
              rules={[{ required: true, message: '请输入验证码' }]}
            >
              <Input placeholder="请输入验证码"
              style={{ width: 186, verticalAlign: "top" }} autoComplete="off" maxLength={6}/>
            </Form.Item>
            <Button disabled={btnDisabled}  className="rg-captcha" onClick ={getCaptcha}>{btnContent}</Button>
        </Form.Item>
        <Form.Item
          name="password"
          label="设置密码"
          rules={[
            {
              required: true,
              message: "请设置6-20位登录密码",
            },
          ]}
          hasFeedback
        >
          <Input placeholder="请设置6-20位登录密码" autoComplete="new-password" type="password" />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="确认密码"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "请再次确认登录密码",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error("两次输入的密码不一致")
                );
              },
            }),
          ]}
        >
          <Input placeholder="请再次确认登录密码" autoComplete="new-password" type="password" />
        </Form.Item>
          <div className="registeraggrement">
            <input type="checkbox" checked={showRead} onChange={()=>confirmRead(!showRead)}></input>
            <span style={{marginLeft:"10px"}}>阅读并接受</span>
            <span className="fuwuxieyi" onClick={()=>Readagree(true)}>《用户服务协议》</span>
          </div>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="register-button">
            注 册
          </Button>
        </Form.Item>
      </Form>
      <Modal
        title="CRISK软件许可及服务协议"
        centered
        cancelText='不同意'
        okText='同意'
        visible={showReadagree}
        onOk={()=>agreeMent(true)}
        onCancel={() => agreeMent(false)}
        width={500}
      >
        <div className="contentinfo">
          欢迎您使用CRISK软件及服务！<br/>
          CRISK软件是生态环境部南京环境科学研究所（以下简称“环境部南京环科所”）版权所有的互联网软件，苏州大实环境科技有限公司（以下简称“大实环境”）通过排他许可取得CRISK软件的使用权。
          为使用CRISK软件（以下统称“本软件”）及服务（以下统称“本服务”），您应当阅读并遵守《CRISK软件许可及服务协议》（以下简称“本协议”）。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制CRISK责任的条款、对用户权利进行限制的条款、约定争议解决方式和司法管辖的条款（如第十二条相关约定）等，以及开通或使用某项服务的单独协议。限制、免责条款或者其他涉及您重大权益的条款可能以加粗、加下划线等形式提示您重点注意。
          除非您已充分阅读、完全理解并接受本协议所有条款，否则您无权下载、安装或使用本软件和/或相关服务。您点击“同意”、“下一步”或您的下载、安装、使用、登录等行为或者您以其他任何明示或者默示方式表示接受本协议的，即视为您已阅读并同意本协议的约束。本协议即在您与大实环境之间产生法律效力，成为对双方均具有约束力的法律文件。
          如果您因年龄、智力等因素而不具有完全民事行为能力，请在法定监护人（以下简称“监护人”）的陪同下阅读和判断是否同意本协议。
          如果您是非中国大陆地区的用户，您订立或履行本协议以及使用本服务的行为还需要同时遵守您所属和/或所处国家或地区的法律。
          <br/>
          <br/>
          <div className="infofont">一、【协议的范围】</div>
          1.1 本协议是您与大实环境之间关于您下载、安装、使用、登录本软件，以及使用本服务所订立的协议。
          <br/>
          <br/>
          <div className="infofont">二、【关于本软件和本服务】</div>
          2.1 本软件是指环境部南京环科所开发并按照本协议及CRISK其他协议、规则之约定，授权用户下载、安装、使用的CRISK软件。
          <br/>
          2.2 您知晓并同意，大实环境可能会根据需要更新或调整本软件和/或本服务的内容。
          <br/>
          2.3 您可能可以通过电脑、手机等终端以客户端等形式使用本服务，具体以大实环境提供的为准。同时，大实环境会不断丰富您使用本服务的终端、形式等。当您使用本服务时，您应选择与您的终端、系统等相匹配的本软件版本，否则，您可能无法正常使用本服务。
          <br/>
          2.4 大实环境给予您一项个人的、不可转让及非排他性的许可，以按照本协议及CRISK其他协议、规则限定的范围和方式使用本软件。
          <br/>
          2.4.1 您可以为非商业目的在单一台终端设备上下载、安装、使用、登录本软件。
          <br/>
          2.4.2 您可以制作本软件的一个副本，仅用作备份。备份副本必须包含原软件中含有的所有著作权信息。
          <br/>
          2.4.3 本软件提供了以《建设用地土壤污染风险评估技术导则（HJ25.3-2019）》等为指导原则，对于建设用地土壤和地下水的污染风险进行评估计算的服务。注册用户在完成项目基本信息输入、分析层次选择以及评估参数输入、模型选择等步骤后，即可生成项目分析报告，完成污染场地风险评估。
          <br/>
          2.4.4 本软件在使用中由用户输入的参数及选择的模型依赖于用户的经验判断，本软件依据指导原则进行评估计算，对于用户输入参数及选择模型的正确性不承担责任。
          <br/>
          2.4.5 本条及本协议其他条款未明示授权的其他一切权利仍由大实环境保留，您在行使这些权利时须另外取得大实环境的书面许可。大实环境如果未行使前述任何权利，并不构成对该权利的放弃。
          <br/>
          <br/>
          <div className="infofont">三、【测试版软件】</div>
          本软件可能提供测试版本，测试版“软件”仅用于在有限的期限内检查、演示和评估软件，其性能和兼容性均未能达到最终全面发布版本的级别，将来也存在对“软件”进行重大更改的可能，其仅供测试“软件”之用。
          <br/>
          <br/>
          <div className="infofont">四、【软件的获取</div>
          4.1 您可以直接从CRISK的官方网站、官方应用分发平台以及CRISK授权的第三方网站、应用分发平台等CRISK官方或CRISK授权的渠道获取本软件。
          <br/>
          4.2 如果您从未经大实环境授权的第三方获取本软件或与本软件名称相同的安装程序，大实环境无法保证该软件能够正常使用，并对因此给您造成的损失不予负责。
          <br/>
          <br/>
          <div className="infofont">五、【软件的安装与卸载】</div>
          5.1 大实环境可能为不同的终端、系统等开发不同的软件版本，您应当根据实际情况选择下载合适的版本进行安装。
          <br/>
          5.2 下载安装程序后，您需要按照该程序提示的步骤正确安装。
          <br/>
          5.3 因经营策略安排或调整等原因，不同地区的用户于本软件可使用的具体服务内容可能会存在差异，具体以大实环境实际提供的为准。
          <br/>
          5.4 如果您不再需要使用本软件或者需要安装新版软件，可以自行卸载。如果您愿意帮助大实环境改进产品服务，请告知卸载的原因。
          <br/>
          <br/>
          <div className="infofont">六、【软件的更新】</div>
          6.1 为了增进用户体验、完善服务内容，大实环境可能不断努力开发新的服务，并为您不时提供软件更新。
          <br/>
          6.2 为了改善用户体验或提高服务安全性、保证功能的一致性等目的，大实环境有权对本软件进行更新，或者对本软件的部分功能效果、服务内容进行改变。
          <br/>
          6.3 本软件新版本发布后，旧版本的软件可能无法使用。大实环境不保证旧版本软件继续可用，也不保证继续对旧版本软件提供相应的客服和维护服务，请您随时核对并下载最新版本。
          <br/>
          <br/>
          <div className="infofont">七、【用户个人信息保护】</div>
          7.1 保护用户个人信息是大实环境的一项基本原则，大实环境将按照本协议的规定收集、使用、储存和分享您的个人信息。
          <br/>
          7.2 您在注册帐号或使用本服务的过程中，可能需要填写一些必要的信息。若国家法律法规（本协议中的“法律法规”指用户所属/所处地区、国家现行有效的法律、行政法规、司法解释、地方法规、地方规章、部门规章及其他规范性文件以及对于该等法律法规的不时修改和补充，以及相关政策规定等，下同）有特殊规定的，您需要填写真实的身份信息（包括但不限于手机号等信息）。若您填写的信息不完整或不真实，则可能无法使用本服务或在使用过程中受到限制。
          <br/>
          7.3 一般情况下，您可根据相关产品规则浏览、修改自己提交的信息，但出于安全性和身份识别（如号码申诉服务等）的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。
          <br/>
          7.4 大实环境将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。
          <br/>
          7.5 大实环境不会将您的个人信息转移或披露给任何第三方，除非：
          <br/>
          （1）相关法律法规或司法机关、行政机关要求；或
          <br/>
          （2）为完成合并、分立、收购或资产转让而转移；或
          <br/>
          （3）为提供您要求的服务所必需。
          <br/>
          <br/>
          <div className="infofont">八、【用户行为规范】</div>
          8.1 【用户注意事项】
          <br/>
          您充分理解并同意：
          <br/>
          8.1.1 为了向您提供更好的服务或维护软件安全的目的，大实环境将按照法律法规规定的“合法、必要、正当”的原则获取、使用您的相关信息。您应了解本部分的信息公开和透露可能会给您带来潜在风险，并自行承担由此造成的风险。
          <br/>
          8.1.2 为进行评估计算，需要您提供项目涉及的污染物及浓度信息。若您提供的信息不完整或不符合事实，则无法使用本服务、在使用过程中受到限制或无法得到正确的反馈。
          <br/>
          8.1.3 为改善、优化相应服务，大实环境会对您的账号以及在本软件中的相关操作等信息进行使用，也可能通过CRISK的服务向您本人、其他用户展示您的相关信息。大实环境也可能会基于保护您的CRISK号码安全等需求，收集、使用您的相关操作行为等信息。
          <br/>
          8.1.4 您在使用本服务某一特定功能时，CRISK可以使用您终端设备的相关权限、接口及相关信息等以实现相应的功能，某些特定功能可能还需同意单独的协议、规则等，您在使用该项功能前请仔细阅读前述相关协议、规则。
          <br/>
          8.1.5 您可以选择不向CRISK提供您的某些信息，或者根据产品设置取消CRISK收集某些信息的权利，但因此可能会导致相关服务功能无法实现。
          <br/>
          8.1.6 大实环境将会尽其商业上的合理努力保障您在本服务中的数据存储安全，但是，大实环境并不能就此提供完全保证，包括但不限于以下情形：
          <br/>
          （1）大实环境不对由于非CRISK原因造成的您在本软件和/或本服务中相关数据的删除或储存失败负责。
          <br/>
          （2）您应自行备份存储在本软件和/或本服务中的数据、信息或与本软件和/或本服务相关的数据、信息，双方另有约定的按相应约定执行。
          <br/>
          （3）如果您停止使用本软件和/或本服务，或因您违反法律法规或本协议而被取消或终止使用本服务，大实环境有权从服务器上永久地删除您的数据。您的服务停止、终止或取消后，大实环境没有义务向您返还任何数据。
          8.1.7 基于用户体验、大实环境或相关服务平台运营安全、平台规则要求及健康发展等综合因素，大实环境有权选择提供服务的对象，决定功能设置，决定功能开放、数据接口和相关数据披露的对象和范围。针对以下情形，有权视具体情况中止或终止提供相关服务，包括但不限于：
          <br/>
          （1）违反法律法规或本协议的。
          <br/>
          （2）影响服务体验的。
          <br/>
          （3）存在安全隐患的。
          <br/>
          （4）与CRISK或其服务平台已有主要功能或功能组件相似、相同，或可实现上述功能或功能组件的主要效用的。
          <br/>
          （5）界面、风格、功能、描述或使用者体验与CRISK或其服务平台类似，可能造成CRISK用户认为其所使用的功能或服务来源于大实环境或经大实环境授权的。
          <br/>
          （6）非法获取用户数据、信息：未经用户明确同意并向用户如实披露数据用途、使用范围等相关系信息的情形下复制、存储、使用或传输用户数据的，包括但不限于要求用户共享个人信息才可使用其功能，或收集用户密码或者用户个人信息，或未经大实环境面许可，通过本软件和/或本服务收集、存储、抓取、获得或要求用户提供包括但不限于CRISK、其服务平台的信息内容、用户数据等大实环境认为属于敏感信息范畴的数据，或者将所有获得的前述数据自行或提供给其用户、客户用于创建、补充或维护自身关系链。
          <br/>
          （7）违背CRISK或其服务平台运营原则，或不符合大实环境其他管理要求的。
          <br/>
          8.2 【用户禁止行为】
          <br/>
          您在使用本软件和/或本服务的过程中，应遵守相关法律法规、用户协议、规则规范等，不得从事包括但不限于以下任何行为，也不得为以下任何行为提供便利：
          8.2.1 法律法规禁止的行为
          <br/>
          您在使用本服务时须遵守法律法规，不得制作、复制、发布、传播含有下列内容的信息或从事相关行为，也不得为制作、复制、发布、传播含有下列内容的信息或从事相关行为提供便利：
          <br/>
          （1）反对宪法所确定的基本原则的。
          <br/>
          （2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的。
          <br/>
          （3）损害国家荣誉和利益的。
          <br/>
          （4）煽动民族仇恨、民族歧视，破坏民族团结的。
          <br/>
          （5）破坏国家宗教政策，宣扬邪教和封建迷信的。
          <br/>
          （6）散布谣言，扰乱社会秩序，破坏社会稳定的。
          <br/>
          （7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的。
          <br/>
          （8）侮辱或者诽谤他人，侵害他人合法权益的。
          <br/>
          （9）违反法律法规底线、社会主义制度底线、国家利益底线、公民合法权益底线、社会公共秩序底线、道德风尚底线和信息真实性底线的“七条底线”要求的。
          <br/>
          （10）法律法规、CRISK协议或规则等禁止的其他行为。
          <br/>
          8.2.2 软件使用
          <br/>
          除非法律法规允许或大实环境书面许可，您不得从事下列行为：
          <br/>
          （1）删除本软件及其副本上关于著作权的信息。
          <br/>
          （2）对本软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本软件的源代码。
          <br/>
          （3）对大实环境K拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等。
          <br/>
          （4）对本软件或者本软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及本软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非CRISK经授权的第三方工具/服务接入本软件和相关系统。
          <br/>
          （5）通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的。
          <br/>
          （6）通过非环境部南京环科所或大实环境开发、授权的第三方软件、插件、外挂、系统，登录或使用本软件和/或本服务，或制作、发布、传播上述工具。
          <br/>
          （7）自行、授权他人或利用第三方软件对本软件和/或本服务及其组件、模块、数据等进行干扰。
          <br/>
          （8）其他可能影响、干扰本软件和/或本服务正常运行的行为。
          <br/>
          8.2.3 数据获取使用
          <br/>
          为了保护数据安全，您不得从事包括但不限于以下行为，也不得为其提供便利：
          <br/>
          （1）未经其他用户明确同意，或在未向其他用户如实披露数据用途、使用范围等相关信息的情形下收集、复制、存储、使用或传输其他用户数据，侵害其他用户合法权益。
          <br/>
          （2）将其他用户账号、手机号、电子邮箱地址和出生日期等个人信息用于任何未经用户及大实环境授权的用途。
          <br/>
          （3）企图进行反射查找、跟踪、关联、挖掘、获取用户账号、手机号、电子邮箱地址和出生日期等个人信息。
          <br/>
          （4）通过各种程序、软件等抓取任何用户的信息或与本软件和/或本服务相关的任何信息、数据。
          <br/>
          （5）其他危害数据安全的行为。
          <br/>
          8.3 【法律责任】
          <br/>
          8.3.1 您理解并同意：若您违反法律法规、本协议和/或CRISK其他协议、规则的，大实环境有权随时单方根据相应情形采取以下一项或多项措施（具体措施以及采取措施的时间长短由CRISK根据您的违法、违约情节相应情况确定）：
          <br/>
          （1）对您进行警告。
          <br/>
          （2） 限制您使用本软件和/或本服务部分或全部功能。
          <br/>
          （3） 删除、屏蔽相关内容或断开链接。
          <br/>
          （4） 中止、终止您对相应账号的使用。
          <br/>
          （5） 采取其他合理、合法的措施。
          <br/>
          （6） 依法追究您的法律责任。
          <br/>
          8.3.2 如果您违反法律法规、本协议和/或CRISK其他协议、规则，大实环境对您或您使用的本软件和/或本服务采取任何行为或措施后，导致您暂时或永久无法使用相关产品和/或服务，您应自行承担相应后果。由此造成您损失的，您应自行承担。
          <br/>
          8.3.3 您导致任何第三方损害的，您应当独立承担责任；大实环境因此遭受损失的，您也应当一并赔偿。
          <br/>
          8.4 【对自己行为负责】
          <br/>
          您充分了解并同意，您必须为自己使用本软件和/或本服务的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。您应对使用本软件和/或本服务时接触到的内容自行加以判断，包括因对内容的正确性、完整性或实用性的依赖而产生的风险，并承担由此引起的相应风险。大实环境在法律允许的范围内不对您因前述风险而导致的任何损失或损害承担责任。
          <br/>
          8.5 【用户投诉处理】
          <br/>
          8.5.1如您被他人投诉或您投诉他人，大实环境有权将争议中相关方的主体、联系方式、投诉相关内容等必要信息提供给相关当事方或相关部门，以便及时解决投诉纠纷，保护各方合法权益。
          <br/>
          <br/>
          <div className="infofont">九、【知识产权声明】</div>
          9.1 环境部南京环科所和大实环境是本软件的知识产权权利人。本软件的著作权、商标权、专利权、商业秘密等知识产权，以及与本软件相关的所有信息内容（包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受法律法规和相应的国际条约保护，环境部南京环科所及大实环境依法享有上述相关知识产权，但相关权利人依照法律规定应享有的权利除外。
          <br/>
          9.2 未经环境部南京环科所、大实环境或相关权利人书面同意，您不得为任何商业或非商业目的自行或许可任何第三方实施、利用、转让上述知识产权。
          <br/>
          9.3 您理解并同意，在使用本软件及相关服务时，您通过本软件上传、发布、共享的任何内容的知识产权归属您或原始著作权人所有。
          <br/>
          <br/>
          <div className="infofont">十、【终端安全责任】</div>
          10.1 您理解并同意，本软件和/或本服务同大多数互联网软件、服务一样，可能会受多种因素影响（包括但不限于用户原因、网络服务质量、社会环境等）；也可能会受各种安全问题的侵扰（包括但不限于他人非法利用用户资料，进行现实中的骚扰；用户下载安装的其他软件或访问的其他网站中可能含有病毒、木马程序或其他恶意程序，威胁您终端的信息和数据的安全，继而影响本软件、本服务的正常使用等），因此，您应加强信息安全及个人信息的保护意识，注意密码保护，以免遭受损失。
          <br/>
          10.2 您不得制作、发布、使用、传播用于窃取CRISK号码和/或他人个人信息、财产的恶意程序。
          <br/>
          10.3 维护软件安全与正常使用是大实环境和您的共同责任，大实环境将按照行业标准合理审慎地采取必要技术措施保护您的终端信息和数据安全，但是您承认和同意CRISK不能就此提供任何保证。
          <br/>
          <br/>
          <div className="infofont">十一、【第三方软件或技术】</div>
          11.1 本软件可能会使用第三方软件或技术（包括本软件可能使用的开源代码和公共领域代码等，下同），这种使用已经获得合法授权。
          <br/>
          11.2 本软件如果使用了第三方的软件或技术，大实环境将按照相关法规或约定，对相关的协议或其他文件，可能通过本协议附件、在本软件安装包特定文件夹中打包等形式进行展示，它们可能会以“软件使用许可协议”“授权协议”“开源代码许可证”或其他形式来表达。
          <br/>
          11.3 前述通过各种形式展现的相关协议或其他文件，均是本协议不可分割的组成部分，与本协议具有同等的法律效力，您应当遵守这些要求。如果您没有遵守这些要求，该第三方或者国家机关可能会对您提起诉讼、罚款或采取其他制裁措施，并要求CRISK给予协助，您应当自行承担法律责任。
          <br/>
          <br/>
          <div className="infofont">十二、【不可抗力及其他免责事由】</div>
          12.1 您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务受到影响。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，CRISK将努力在第一时间与相关单位配合，争取及时进行处理，但是由此给您造成的损失，CRISK在法律允许的范围内免责。
          <br/>
          12.2 在法律允许的范围内，大实环境对以下情形导致的服务中断或受阻不承担责任：
          <br/>
          （1）受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏。
          <br/>
          （2）用户或CRISK的电脑软件、系统、硬件和通信线路出现故障。
          <br/>
          （3）用户操作不当或用户通过非大实环境授权的方式使用本服务。
          <br/>
          （4）程序版本过时、设备的老化和/或其兼容性问题。
          <br/>
          （5）其他CRISK无法控制或合理预见的情形。
          <br/>
          12.3 您理解并同意，在使用本服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，CRISK不对任何信息的真实性、适用性、合法性承担责任，也不对因侵权行为给您造成的损害负责。这些风险包括但不限于：
          <br/>
          （1）来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息。
          <br/>
          （2）遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失。
          <br/>
          （3）其他因网络信息或用户行为引起的风险。
          <br/>
          12.4 大实环境依据本协议约定获得处理违法违规内容的权利，该权利不构成大实环境的义务或承诺，大实环境不能保证及时发现违法行为或进行相应处理。
          <br/>
          12.5 在任何情况下，您不应轻信借款、索要密码或其他涉及财产的信息。涉及财产操作的，请一定先核实对方身份，并请经常留意CRISK有关防范诈骗犯罪的提示。
          <br/>
          <br/>
          <div className="infofont">十三、【其他】</div>
          <br/>
          13.1 您使用本软件和/或本服务即视为您已阅读本协议并接受本协议的约束。CRISK有权在必要时修改本协议条款。您可以在相关服务页面查阅最新版本的协议条款。本协议条款变更后，如果您继续使用CRISK提供的软件或服务，即视为您已接受变更后的协议。
          <br/>
          13.2 本协议签订地为中华人民共和国江苏省苏州市。
          <br/>
          13.3 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。
          <br/>
          13.4 若您和大实环境之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地（即江苏省苏州市）有管辖权的人民法院管辖。
          <br/>
          13.5 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
          <br/>
          13.5 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
          <br/>
          13.7 若本协议有中文、英文等多个语言版本，相应内容不一致的，均以中文版的内容为准。（完）
        </div>
      </Modal>
    </div>
  );
};

export default RegistrationForm;
