
import { createApi } from '../configs/ajax'
import { baseURL, /* baseURL, */ path } from '../configs/config'

const prefix = 'user';
const predata = 'data';
const wechat = 'wechat';
const preproject = 'project';
const preoutput = 'output'
const defaulturl = '/api/v1';
const front = 'front_end_jump';
const pay = 'pay';
const option = { baseURL: baseURL }

export const login = createApi(`${defaulturl}/${prefix}/login`, option) // 登陆
export const getVerifyCode = createApi(`${defaulturl}/${prefix}/getVerifyCode`, option) // 验证码
export const register = createApi(`${defaulturl}/${prefix}/register`, option) // 注册
export const resetpassword = createApi(`${defaulturl}/${prefix}/resetPassword`, option) // 重置密码
export const submitFeedBack = createApi(`${defaulturl}/${prefix}/submitFeedBack`, option) // 提交用户反馈
export const updateInfo = createApi(`${defaulturl}/${prefix}/updateInfo`, option) // 更新用户信息



export const getSoilPollutant = createApi(`${defaulturl}/${predata}/getSoilPollutant`, option) // 拉取表单数据
export const addCustomPollutant = createApi(`${defaulturl}/${predata}/addCustomPollutant`, option) // 增加自定义污染物
export const listCustomPollutant = createApi(`${defaulturl}/${predata}/listCustomPollutant`, option) // 获取自定义污染物
export const deleteCustomPollutant = createApi(`${defaulturl}/${predata}/deleteCustomPollutant`, option) // 删除自定义污染物
export const updateCustomPollutant = createApi(`${defaulturl}/${predata}/updateCustomPollutant`, option) // 更新自定义污染物
export const getStandardDocuments = createApi(`${defaulturl}/${predata}/getStandardDocuments`, option) // 标准文件查询
export const getGroundWaterPollutant = createApi(`${defaulturl}/${predata}/getGroundWaterPollutant`, option) // 地下水筛选值查询
export const getStandardPollutant = createApi(`${defaulturl}/${predata}/getStandardPollutant`, option) // 标准污染物筛选值查询

export const createProject = createApi(`${defaulturl}/${preproject}/createProject`, option) // 项目信息
export const getProjectInfo = createApi(`${defaulturl}/${preproject}/getProjectInfo`, option) // 获取项目信息
export const updateProject = createApi(`${defaulturl}/${preproject}/updateProject`, option) // 更新项目信息接口

export const levelAnalysis = createApi(`${defaulturl}/${preproject}/levelAnalysis`, option) // 层次分析
export const firstReport = createApi(`${defaulturl}/${preproject}/firstReport`, option) // 第一层次报告
export const getSystemPollutantList = createApi(`${defaulturl}/${preproject}/getSystemPollutantList`, option) // 获取系统污染物接口
export const getProjectPollutant = createApi(`${defaulturl}/${preproject}/getProjectPollutant`, option) // 获取添加的污染物接口
export const getCriskPollutant40 = createApi(`${defaulturl}/${preproject}/getCriskPollutant40`, option) // 建设用地新国标40项
export const getCriskPollutant45 = createApi(`${defaulturl}/${preproject}/getCriskPollutant45`, option) // 建设用地新国标45项
export const bindPollutantAndProject = createApi(`${defaulturl}/${preproject}/bindPollutantAndProject`, option) // 添加关注污染物接口, 模态框选择污染物后与项目绑定
export const deleteBindPollutant = createApi(`${defaulturl}/${preproject}/deleteBindPollutant`, option) // 删除绑定的污染物接口
export const differBackgroundValue = createApi(`${defaulturl}/${preproject}/differBackgroundValue`, option) // 是否勾选输入背景值接口
export const differDepth = createApi(`${defaulturl}/${preproject}/differDepth`, option) // 更改是否区分地表土壤和下层土壤接口
export const differFilterGroundWater = createApi(`${defaulturl}/${preproject}/differFilterGroundWater`, option) // 是否勾选地下水筛选值4类接口
export const savePollutantValue = createApi(`${defaulturl}/${preproject}/savePollutantValue`, option) // 保存浓度信息接口
export const getModelStatus = createApi(`${defaulturl}/${preproject}/getModelStatus`, option) // 获取用户保存状态
export const saveModelStatus = createApi(`${defaulturl}/${preproject}/saveModelStatus`, option) // 保存暴露途径及模型
export const getProjectParamsValue = createApi(`${defaulturl}/${preproject}/getProjectParamsValue`, option) // 保存暴露途径及模型
export const saveProjectParamsValue = createApi(`${defaulturl}/${preproject}/saveProjectParamsValue`, option) // 保存设置的项目参数信息
export const listProject = createApi(`${defaulturl}/${preproject}/listProject`, option) // 获取项目列表
export const deleteProject = createApi(`${defaulturl}/${preproject}/deleteProject`, option) // 批量刪除項目接口

export const saveExpertConsultation = createApi(`${defaulturl}/${pay}/saveExpertConsultation`, option) // 专家咨询


export const listPollutant = createApi(`${defaulturl}/${preoutput}/listPollutant`, option) // 污染物参数列表
export const riskCalculation = createApi(`${defaulturl}/${preoutput}/riskCalculation`, option) // 风险计算
export const exposedQuantityTable = createApi(`${defaulturl}/${preoutput}/riskCalculation`, option) // 暴露量计算
export const contribute = createApi(`${defaulturl}/${preoutput}/riskCalculation`, option) // 贡献率计算
export const secondReport = createApi(`${defaulturl}/${preoutput}/secondReport`, option) // 第二层次
export const reportPath = createApi(`${defaulturl}/${preoutput}/reportPath`, option) // 选择层次


export const getQRcode = `${baseURL}${defaulturl}/${wechat}/getQRcode`
export const queryStatus = `${baseURL}${defaulturl}/${wechat}/queryStatus`
export const bindWechat = `${baseURL}${defaulturl}/${wechat}/bindWechat`

export const setaddParam=createApi(`${defaulturl}/${front}/addParam`, option) // 污染物参数列表