
import isElectron from 'is-electron';
const electronWin =()=>{
    if(isElectron){
        // const { ipcRenderer } = window.require("electron");
        // 关闭窗口
        return {
            closeWindow:()=>{
                //ipcRenderer.send("close");
            },
            // 最小化窗口
             minWindow:()=>{
                //ipcRenderer.send("min");
            },
            // 最小化窗口
            maximizeWindow:()=>{
                //ipcRenderer.send("maximize");
            },
            // 最小化窗口
            unmaximizeWindow:()=>{
                //ipcRenderer.send("unmaximize");
            },
            sendWindowZero:()=>{
                //ipcRenderer.send("windowZero");
            },
            showWindow:()=>{
                //ipcRenderer.send("showWindow");
            }
        }
    }else{
        return {
            closeWindow:()=>{
       
            },
            minWindow:()=>{
    
            },
            maximizeWindow:()=>{
    
            },
            unmaximizeWindow:()=>{

            },
            sendWindowZero:()=>{
                
            },
            showWindow:()=>{

            }
        }
    }
}



export const closeWindow= electronWin().closeWindow

export const minWindow= electronWin().minWindow

export const maximizeWindow= electronWin().maximizeWindow

export const unmaximizeWindow= electronWin().unmaximizeWindow

export const sendWindowZero= electronWin().sendWindowZero

export const showWindow= electronWin().showWindow