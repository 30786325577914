import * as React from "react";
import { withRouter } from "react-router-dom";
import "../../assets/styles/login.less";
import { login } from "../../apis/common";
import { message, Spin } from "antd";
import { connect } from 'react-redux';
import { removeToken, removeUser, removeSessionProductId, removeSessionLevel } from '../../utils/token';
// const { ipcRenderer } = window.require("electron");
import { closeWindow, minWindow, maximizeWindow, sendWindowZero } from '../../configs/electronHandle'
const mapStateToProps = (state: any) => {
  return {
    token: state.setUserInfo.token
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  setUserInfo: (data: any) => dispatch({ type: 'SET_USER_INFO', plalod: data }),
});

interface IProps {
  login: any;
  register: any;
  history: any;
  retrieve: any;
  token: any,
  setUserInfo: (data: any) => any
}

interface IState {
  account: string,
  password: string
  autoLogin: boolean;
  loading: boolean,
  checkedStatus: boolean
}

@(connect(mapStateToProps, mapDispatchToProps,) as any)
class Login extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      account: "",
      password: "",
      autoLogin: false,
      loading: false,
      checkedStatus: false
    };
  }
  // closeWindow=()=>{
  //   // window.close();
  //   ipcRenderer.send("close");
  // };
  // minWindow=()=>{
  //   ipcRenderer.send("min");
  // };
  handleRegister = () => {
    const { history } = this.props
    history.push("/register")
  }

  handleForget = () => {
    const { history } = this.props
    history.push("/forget")
  }

  handleScan = () => {
    const { history } = this.props
    history.push("/scan")
  }
  async handleLogin(event: any) {
    console.log('333')
    let {
      account,
      password,
    } = this.state;
    if (!account || !password) {
      message.warning('用户名和密码不能为空')
      return
    }
    this.setPassword()
    this.setState({
      loading: true
    })

    await login({
      password,
      account
    }).then((res: any) => {
      // maximizeWindow()
      this.setState({
        loading: false
      })
      this.props.setUserInfo(res.data);
      const { history } = this.props
      sendWindowZero()
      history.push("/project/info")
    }).catch((err: any) => {
      this.setState({
        loading: false,
        checkedStatus: false
      })

    })
  }
  //获取登陆账户
  getAdminInputData = (event: any) => {
    this.setState({ account: event.target.value });
  }
  //获取密码
  getPasswordInputData = (event: any) => {
    this.setState({ password: event.target.value });
  }
  rememberPassword = (id: any) => {
    this.setState({
      checkedStatus: !this.state.checkedStatus
    })
  }
  setPassword = () => {
    //记住密码
    if (this.state.checkedStatus) {   //是否保存密码
      let accountInfo = this.state.account + '&' + this.state.password;
      let Days = 7;  //cookie保存时间
      let exp = new Date();
      exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
      document.cookie = 'accountInfo' + "=" + escape(accountInfo) + ";expires=" + exp.toUTCString();
    } else {
      this.clearAllCookie()
    }
  }
  clearAllCookie = () => {
    var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
    if (keys) {
      for (var i = keys.length; i--;)
        document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString()
    }
  }
  componentDidMount() {
    removeToken()
    removeUser()
    removeSessionProductId()
    removeSessionLevel()
    this.loadAccountInfo()
    document.body.addEventListener("keyup", (e) => {
      let code = e.charCode || e.keyCode
      if (code === 13) {
        this.handleLogin(this)
      }
    })
  }
  componentWillUnmount() {
    document.body.removeEventListener("keyup", () => { })
  }
  //判断cookie中是否有账号信息，有就可以进行预填写，没有则直接返回
  loadAccountInfo = () => {
    //读取cookie
    let arr, reg = new RegExp("(^| )" + 'accountInfo' + "=([^;]*)(;|$)");
    console.log("arr", reg, arr, arr = document.cookie.match(reg), document.cookie);

    let accountInfo = ''
    console.log("accountInfo", accountInfo);

    if (arr = document.cookie.match(reg)) {
      accountInfo = unescape(arr[2]);
      console.log("accountInfo", accountInfo);
    } else {
      accountInfo = '';
    }
    if (Boolean(accountInfo) == false) {
      return false;
    } else {


      let i = accountInfo.split("&");
      console.log("i", i);

      this.setState({
        account: i[0],
        password: i[1],
        checkedStatus: true
      })
    }
  };
  render() {
    return (
      <div className="login-container win-drag">
        <Spin spinning={this.state.loading} size='large' tip="正在登录...">
          <div className="login-container-box">
            <div className=" login-container_fl fl">
              <div className=" login-container_fl_top_img">
                <img
                  src={require("../../assets/images/login/login-fl.png").default}
                  alt=""
                />

              </div>
              <div className=" login-container_fl_box">


                <div className=" login-container_fl_box_center">
                  <h3>污染场地风险评估系统</h3>
                  <div className=" login-container_fl_center_img">
                    <img
                      src={require("../../assets/images/login/login-center.png").default}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="login-container_fr fr">
              <div className="login-header">
                {/* <div className="login-header-button-container">
                  <div className="login-space"></div>
                  <img
                    src={require("../../assets/images/login/min_button.png").default}
                    alt=""
                    onClick={minWindow}
                    className="win-no-drag"
                  />
                  <img
                    src={require("../../assets/images/login/close_button.png").default}
                    alt=""
                    onClick={closeWindow}
                    className="win-no-drag"
                  />
                </div> */}
                <div className="login-header-logo-container">
                  <div className="login-space"></div>
                  {/* <img
              src={require("../../assets/images/login/login_logo.png").default}
              alt=""
            /> */}
                  <div className="login-space"></div>
                </div>
                {/* <div className="login-header-title">污染场地风险评估系统</div> */}

              </div>
              <div className="login-form">
                <div className="login-header_fr-title">欢迎登录</div>
                <div className="input-box">
                  <div className="input-container">
                    <div className="login-space"></div>
                    <div className="input-container-box">
                      <img
                        src={require("../../assets/images/login/user.png").default}
                        alt=""
                      />
                      <input autoComplete="off" placeholder="用户名" value={this.state.account} onChange={this.getAdminInputData} />
                    </div>
                    <div className="login-space"></div>
                  </div>
                  <div className="input-container">
                    <div className="login-space"></div>
                    <div className="input-container-box">
                      <img
                        src={require("../../assets/images/login/password.png").default}
                        alt=""
                      />
                      <input autoComplete="new-password" placeholder="密码" type="password" value={this.state.password} onChange={this.getPasswordInputData} />
                    </div>
                    <div className="login-space"></div>
                  </div>
                </div>
                <div className="login-option">
                  <div className="login-space"></div>
                  <div className="login-option-container">
                    <input type="checkbox"
                      checked={this.state.checkedStatus}
                      onChange={this.rememberPassword.bind(this)}
                    ></input>
                    <span style={{ marginLeft: "10px" }} className="win-no-drag">记住密码</span>
                    <div className="login-space"></div>
                    <span style={{ cursor: "pointer" }} onClick={this.handleForget} className="win-no-drag">忘记密码</span>
                  </div>
                  <div className="login-space"></div>
                </div>
                <button className="login-btn win-no-drag" onClick={this.handleLogin.bind(this)}>登录</button>
                <div className="login-others">
                  <div className="login-space"></div>
                  <div className="login-others-container">
                    {/* <span style={{marginRight:"10px"}}>其他登录方式</span>
                <img
                src={require("../../assets/images/login/wechat.png").default}
                alt=""
                onClick={this.handleScan}
                /> */}
                    {/* <div className="login-space"></div> */}
                    <span style={{ color: "#3F59AE", cursor: "pointer" }} onClick={this.handleRegister} className="win-no-drag">新用户注册</span>
                  </div>
                  <div className="login-space"></div>

                </div>
                <div className="login-bottom-title">
                  知识产权所有单位：生态环境部南京环境科学研究所
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
export default withRouter(Login);


