import React from 'react';
import { withRouter } from "react-router-dom";
import { Modal, Space, Input, Button, Tabs, Form, Row, Col, Radio, message, Checkbox } from 'antd';
// import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import DataTable from './dataTable.tsx';
import '../../assets/styles/common.less';
import { addCustomPollutant, bindPollutantAndProject } from '../../apis/common'
import { checkProductd } from '../../configs/checkProductId.js'
import { async } from 'q';
const { TabPane } = Tabs;

class popEditData extends React.Component {
  formRef = React.createRef();
  getdata = React.createRef();
  systemdata = React.createRef();
  state = {
    modal1Visible: false,
    modalPollute: false,
    printValue: '',
    ISKOC: true,
    tabs: 1
  };
  componentDidMount() {
    this.props.triggerRef(this);
    let productId = checkProductd();
    if (productId) {
      this.setState({
        project_id: productId
      })
    }
  }
  setModal1Visible(modal1Visible) {
    this.setState({ modal1Visible })
    console.log("modal1Visible",modal1Visible);
    let selectRowIds = ''
    if (this.getdata.current) {
      selectRowIds += this.getdata.current.state.selectRowIds;
    }
    if (this.systemdata.current) {
      selectRowIds += this.systemdata.current.state.selectRowIds
    }
    console.log("selectRowIds",this.systemdata.current);
    if (selectRowIds.length > 0) {
      selectRowIds = selectRowIds.substr(0, selectRowIds.length - 1);
      bindPollutantAndProject({
        project_id: this.state.project_id,
        pollutant_ids: selectRowIds
      }).then((res) => {
        this.props.downProps()
      }).catch((err) => {
        message.warn('服务器异常，请稍后再试')
      })
    }
  }
  addPollute(modalPollute) {
    this.setState({ modalPollute })
  }
  searchTable() {
    if (this.state.tabs == 1) {
      this.systemdata.current.systemPollutants({ keyword: this.state.printValue })
    } else if (this.state.tabs == 2) {
      this.getdata.current.definitionPollutants({ keyword: this.state.printValue })
    }
  }
  selectTabs(e) {
    this.setState({
      tabs: e
    })
  }
  getChange(value) {
    let printValue = value.target.value;
    if (!printValue) {
      if (this.state.tabs == 1) {
        this.systemdata.current.systemPollutants({ keyword: '' })
      } else if (this.state.tabs == 2) {
        this.getdata.current.definitionPollutants({ keyword: '' })
      }
    }
    this.setState({
      printValue
    })
  }
  changeRadio(i) {
    if (i.target.value == '是') {
      this.setState({
        ISKOC: true
      })
    } else if (i.target.value == '否') {
      this.setState({
        ISKOC: false
      })
    }
  }
  // 注册登陆
  fnAddPollutant = async (values) => {
    let fieldsValue = await this.formRef.current.validateFields();
    addCustomPollutant({
      ...fieldsValue
    }).then((res) => {
      this.formRef.current.resetFields()
      this.setState({ modalPollute: false })
      this.getdata.current.definitionPollutants()
    }).catch((err) => {
      message.warn('服务器异常，请稍后再试')
    })
  };

  render() {
    return (
      <div>
        <Modal
          title="添加系统污染物数据库"
          visible={this.state.modal1Visible}
          onOk={() => this.setModal1Visible(false)}
          onCancel={() => this.setModal1Visible(false)}
          cancelText="取消"
          okText="确定"
          width={1000}
          destroyOnClose={true}
        >
          <Space>
            <Input placeholder="请输入污染物中英文名或CAS编号" size="large" allowClear={true} onChange={this.getChange.bind(this)} />
            <Button type="primary" onClick={this.searchTable.bind(this)} >
              搜索
            </Button>
          </Space>
          <Tabs defaultActiveKey="1" type="card" onChange={this.selectTabs.bind(this)} className="TabsClass">
            <TabPane tab="系统污染物数据库" key="1">
              <DataTable type="system" upprops={this} ref={this.systemdata}></DataTable>
            </TabPane>
            <TabPane tab="自定义污染物数据库" key="2">
              <div className="followbtn">
                <button className="addbtn" onClick={this.addPollute.bind(this, true)}>+添加</button>
              </div>
              <DataTable type="definition" ref={this.getdata} upprops={this}></DataTable>
            </TabPane>
          </Tabs>
        </Modal>
        <Modal
          title="添加自定义污染物数据库"
          visible={this.state.modalPollute}
          onOk={() => this.fnAddPollutant(false)}
          onCancel={() => this.addPollute(false)}
          cancelText="取消"
          okText="确定"
          width={1000}
          destroyOnClose={true}
        >
          <Form layout="vertical" onFinish={this.addPollute} ref={this.formRef} preserve={false}>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item label="中文名：" name="NAME_CN">
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="英文名：" name="NAME_EN">
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="CAS编号：" name="CAS_CODE">
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="亨利常数(无量纲)：" name="HSTRIKE">
                  <Input></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item label="亨利常数数据来源：" name="HSTRIKE_SOURCE">
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Da(cm²/s)：" name="DA">
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Da数据来源：" name="DA_SOURCE">
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Dw(cm²/s)：" name="DW">
                  <Input></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item label="Dw数据来源：" name="DW_SOURCE">
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Koc/Kd数据来源：" name="ISKOC_SOURCE">
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="溶解度(mg/L)：" name="S">
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="溶解度数据来源：" name="S_SOURCE">
                  <Input></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item label="Sfo(mg/kg-d)-1：" name="SFO">
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Sfo数据来源：" name="SFO_SOURCE">
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="IUR(mg/m³)-1：" name="IUR">
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="IUR数据来源：" name="IUR_SOURCE">
                  <Input></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item label="RfDo(mg/kg-d)：" name="RFDO">
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="RfDo数据来源：" name="RFDO_SOURCE">
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="RfC(mg/m³)：" name="RFC">
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="RfC数据来源：" name="RFC_SOURCE">
                  <Input></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item label="ABSgi(无量纲)：：" name="ABSGI">
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="ABSgi(无量纲)数据来源：" name="ABSGI_SOURCE">
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="ABSd(无量纲)：" name="ABSD">
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="ABSd(无量纲)数据来源：" name="ABSD_SOURCE">
                  <Input></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item label="Carbon atoms per molecule(n)：" name="CARBON">
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Hydrogen atoms per molecule(m)：" name="HYDROGEN">
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Oxygen atoms per molecule(m)：" name="OXYGEN">
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Stoichiometric factor δ [g/g]：" name="STOICHIOMETRIC">
                  <Input></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item label="反应常数：" name="KOBS">
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="是否VOC：" name="ISVOC">
                  <Radio.Group defaultValue={'是'}>
                    <Radio value={'是'}>是</Radio>
                    <Radio value={'否'}>否</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {
                this.state.ISKOC ? (<Col span={6}>
                  <Form.Item label="Koc(cm³/g)：" name="KOC">
                    <Input></Input>
                  </Form.Item>
                </Col>) : (<Col span={6}>
                  <Form.Item label="Kd(cm³/g)：" name="KD">
                    <Input></Input>
                  </Form.Item>
                </Col>)
              }
              <Col span={6}>
                <Form.Item label="是否基于Koc计算Kd：" name="ISKOC">
                  <Radio.Group defaultValue={'是'} onChange={this.changeRadio.bind(this)}>
                    <Radio value={'是'}>是</Radio>
                    <Radio value={'否'}>否</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>

      </div>
    );
  }
}
export default withRouter(popEditData);