import React, { useEffect, useState } from "react";
import { Form, Input, Checkbox, Button ,message} from "antd";
import { getVerifyCode ,resetpassword} from "../apis/common";
import { withRouter,useHistory } from "react-router-dom";
import config from '../configs/index';
//TODO: 页面跳转保留数据
let timeChange: any;
const ForgetForm = () => {
   // 初始化页面常量 绑定事件方法
  const [form] = Form.useForm();
  let history = useHistory();
  const [success, setSuccess] = useState(false);
  const [time, setTime] = useState(60);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnContent, setBtnContent] = useState("获取验证码");
  const [phoneNumber,setPhoneNumber] = useState('');

    // 注册登陆
    const onFinish = async (values: any) => {
      let {
        captcha,
        password,
        phone
      } =values;
      await resetpassword({      
        "password": password,
        "verifyCode": captcha,
        "phone": phone
      }).then((res:any)=>{
        setSuccess(true)
      }).catch((err:any)=>{
        // message.warn('服务器异常，请稍后再试')
      })
    };

  useEffect(() => {
    clearInterval(timeChange);
    return () => clearInterval(timeChange);
  }, []);

  useEffect(() => {
    if (time > 0 && time < 60) {
      setBtnContent(`${time}s`);
    } else {
      clearInterval(timeChange);
      setBtnDisabled(false);
      setTime(60);
      setBtnContent("获取验证码");
    }
  }, [time]);
   // 获取用户手机号
   const getPhone = (event:any ) =>{
    if(event.target.value){
      setPhoneNumber(event.target.value)
    }else{
      setPhoneNumber('')
    }
  }
    // 获取手机验证码
    const getCaptcha = async() =>{
      if(!phoneNumber){
        message.warning('请先输入手机号');
        return
      }
      await getVerifyCode({      
        "phone":phoneNumber,
        "dev":0,
        "type":2
      }).then((res:any)=>{
        timeChange = setInterval(() => setTime((t) => --t), 1000);
        setBtnDisabled(true);
        message.success('发送成功');
      }).catch((err:any)=>{
      })
    }
    const gotologin =() =>{
      history.push("/login")
    }
  return (
    <div className="forget-form-container">
      {success === false ? (
        <div>
          <Form
            form={form}
            name="forget"
            onFinish={onFinish}
            scrollToFirstError
          >
            <Form.Item
              name="phone"
              label="手机号"
              rules={[
                {
                  required: true,
                  message: "请输入手机号",
                },
              ]}
            >
              <Input placeholder="请输入手机号" autoComplete="off" onChange={getPhone}/>
            </Form.Item >
              <Form.Item label="验证码">
              <Form.Item
                name="captcha"
                noStyle
                rules={[{ required: true, message: '请输入验证码' }]}
              >
                <Input placeholder="请输入验证码"
                style={{ width: 186, verticalAlign: "top" }} autoComplete="off" maxLength={6}/>
              </Form.Item>
              <Button disabled={btnDisabled}  className="rg-captcha" onClick ={getCaptcha}>{btnContent}</Button>
            </Form.Item>
            <Form.Item
              name="password"
              label="重置密码"
              rules={[
                {
                  required: true,
                  message: "请设置6-20位登录密码",
                },
              ]}
              hasFeedback
            >
              <Input placeholder="请设置6-10位登录密码" autoComplete="off" type="password" maxLength={15} minLength={6}/>
            </Form.Item>

            <Form.Item
              name="confirm"
              label="确认密码"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "请再次确认登录密码",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error("两次输入的密码不一致"));
                  },
                }),
              ]}
            >
              <Input placeholder="请再次确认登录密码" autoComplete="off" maxLength={15} minLength={6} type="password" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="forget-button"
              >
                确 定
              </Button>
            </Form.Item>
          </Form>
          <div
            style={{
              textAlign: "center",
              fontSize: "16px",
              fontFamily: "Regular",
              fontWeight: 400,
              height: "22px",
              color: "#3F59AE",
              marginTop: "-6px",
            }}
          >
            <a onClick={gotologin}>账号密码登录</a>
          </div>
        </div>
      ) : (
        <div className="forget-content">
          <div className="forget-logo-container">
            <div className="forget-space"></div>
            <img
              src={require("../assets/images/login/check-circle-fill.png").default}
              alt=""
            />
            <div className="forget-space"></div>
          </div>
          <div style={{fontFamily:"Regular", fontWeight:500, height:"32px", color: "rgba(0, 0, 0, 0.85)", textAlign:"center", fontSize:"24px", marginTop:"-5px"}}>密码修改成功</div>
          <div style={{fontFamily:"Regular", fontWeight:400, height:"22px", color:"rgba(0, 0, 0, 0.45)", textAlign:"center", fontSize:"16px", marginTop:"10px"}}>请使用新的密码进行登录</div>
          <Button className="forget-login"><a onClick={gotologin}>立即登录</a></Button>
        </div>
      )}
    </div>
  );
};

export default ForgetForm;
