import React, { Component } from 'react';
import './controlModel.less'
import {getModelStatus} from '../../apis/common';
import {checkProductd} from '../../configs/checkProductId.js'
import { set } from '../../configs/config';
class Model extends Component{
    constructor(props){
        super(props)
        this.state={
            controlShow:[
                false,false,false,false,false,
                false,false,false,false,false,
                false,false,false,false,false,
                false,false,false,false,false,
                false,false,false,false
            ],
            project_id:''
        }
    }
    componentDidMount(){
        this.getModelStatus()
    }
    renderHtml= (render)=>{
        let renderArr = [
            [3,6,8,9,14,16,17],
            [2,3,6,14,21],
            [3,6,7,10,12,14,15,18,20],
            [3,5,6,7,10,11,14],
            [0,6,7,10,11,14],
            [1,3,6,14,15,18,19],
            [6,7,10,11,14,22],
            [6,14,15,18,19,23],
            [3,4,6,13,14]
        ]
        let type = [];
        render.forEach((value,index)=>{
            if(value=='1'){
                type= type.concat(renderArr[index])
            }
        })
        type = Array.from(new Set(type))
        if(type.length==0){
            type= [6,14]
        }
        let controlShow = this.state.controlShow;
        type.sort(function(a, b) { return b - a});
        type.forEach(function(index) { controlShow.splice(index, 1,true) })
        this.setState({
            controlShow
        })
    }
    componentWillMount(){
        let productId=checkProductd();
        if(productId){
          this.setState({
            project_id:productId
          })
        }
    }
    getModelStatus=async()=>{
        let selectArr= [];
        await getModelStatus({
          project_id:this.state.project_id
        }).then((res)=>{
            let soil = res.data.soil;
            let water = res.data.water;
            soil.forEach(element => {
                selectArr.push(element.selected)
            });
            water.forEach(element => {
            selectArr.push(element.selected)
            });
            this.renderHtml(selectArr)
        }).catch((err)=>{
            console.log(err)
        })
      }
    render(){
        return(
            <div className="controlmodel">
                <img style={{display:this.state.controlShow[0]?'block':'none'}} src={require( `../../assets/images/controlModel/waihuo.png`).default} className="waihuo"/>
                <img style={{display:this.state.controlShow[1]?'block':'none'}} src={require( `../../assets/images/controlModel/fanghuo.png`).default} className="fanghuo"/>
                
                <img style={{display:this.state.controlShow[2]?'block':'none'}} src={require( `../../assets/images/controlModel/re.png`).default} className="re"/>
                <img style={{display:this.state.controlShow[3]?'block':'none'}}src={require( `../../assets/images/controlModel/di.png`).default} className="di"/>
                <img style={{display:this.state.controlShow[4]?'block':'none'}} src={require( `../../assets/images/controlModel/tong.png`).default} className="tong"/>
                <img style={{display:this.state.controlShow[5]?'block':'none'}} src={require( `../../assets/images/controlModel/sixjiantou.png`).default} className="sixjiantou"/>
                <img style={{display:this.state.controlShow[6]?'block':'none'}} src={require( `../../assets/images/controlModel/ren.png`).default} className="rightren"/>
                <img style={{display:this.state.controlShow[7]?'block':'none'}} src={require( `../../assets/images/controlModel/fei.png`).default} className="rightfei"/>
                <img style={{display:this.state.controlShow[8]?'block':'none'}} src={require( `../../assets/images/controlModel/qiguan.png`).default} className="rightqiguan"/>
                <img style={{display:this.state.controlShow[9]?'block':'none'}} src={require( `../../assets/images/controlModel/up.png`).default} className="rightup"/>
                {/* <img style={{display:this.state.controlShow[10]?'block':'none'}} src={require( `../../assets/images/controlModel/down.png`).default} className="rightdown"/> */}
                <img style={{display:this.state.controlShow[11]?'block':'none'}} src={require( `../../assets/images/controlModel/qi.png`).default} className="rightqi"/>
                <img style={{display:this.state.controlShow[12]?'block':'none'}} src={require( `../../assets/images/controlModel/wu.png`).default} className="rightwu"/>
                <img style={{display:this.state.controlShow[13]?'block':'none'}} src={require( `../../assets/images/controlModel/qiguan.png`).default} className="rightqiguan"/>
                <img style={{display:this.state.controlShow[14]?'block':'none'}} src={require( `../../assets/images/controlModel/ren.png`).default} className="leftren"/>
                <img style={{display:this.state.controlShow[15]?'block':'none'}} src={require( `../../assets/images/controlModel/fei.png`).default} className="leftfei"/>
                <img style={{display:this.state.controlShow[16]?'block':'none'}} src={require( `../../assets/images/controlModel/qiguan.png`).default} className="leftqiguan"/>
                <img style={{display:this.state.controlShow[17]?'block':'none'}} src={require( `../../assets/images/controlModel/up.png`).default} className="leftup"/>
                {/* <img style={{display:this.state.controlShow[18]?'block':'none'}} src={require( `../../assets/images/controlModel/down.png`).default} className="leftdown"/> */}
                <img style={{display:this.state.controlShow[19]?'block':'none'}} src={require( `../../assets/images/controlModel/qi.png`).default} className="leftqi"/>
                <img style={{display:this.state.controlShow[20]?'block':'none'}} src={require( `../../assets/images/controlModel/wu.png`).default} className="leftwu"/>
                <img style={{display:this.state.controlShow[21]?'block':'none'}} src={require( `../../assets/images/controlModel/re.png`).default} className="leftre"/>

                <img style={{display:this.state.controlShow[22]?'block':'none'}} src={require( `../../assets/images/controlModel/waishui.png`).default} className="waishui"/>
                <img style={{display:this.state.controlShow[23]?'block':'none'}} src={require( `../../assets/images/controlModel/fangwater.png`).default} className="fangwater"/>
            </div>
        )
    }
}
export default Model