import React, { Component } from 'react';
import './conceptModel.less';
import Model from '../../../components/controlModel/controlModel'
import LineChart from '../../../components/lineChart/lineChart'
import LineImg from '../../../components/lineImg/lineImg'
class Demo extends Component {
    constructor(props) {
        super(props);
        this.state = {
        
        }
    }
 
    componentDidMount() {
        // this.renderDag();     
    }
    gotoTap= async(step)=>{
      if(step == 'back'){
        this.props.history.goBack()
        return
      }
      if(step == 'next'){
        this.props.history.push({
          pathname:"/project/polluteParamsTable"
        })
      }
    }
    render() {
        return (
            <div>
              <Model></Model>
              <div className="modelbackground">
                <LineImg></LineImg>
              </div>
              <div className="exposebtn">
                  <button className="formbtnleft" onClick={this.gotoTap.bind(this,'back')}>上一步</button>
                  <button className="formbtnright" onClick={this.gotoTap.bind(this,'next')}>下一步</button>
              </div>
            </div>
         
        )
    }
}
 
export default Demo;