import * as React from "react";
import { Menu, Modal, Form, Col, Row, Dropdown, Space, message, Button, Radio, Input, Tabs, Table, Divider, Popconfirm } from "antd";
// import "./headerTop.less";
import "./headerTopc.css";
import { submitFeedBack, getVerifyCode, updateInfo, getStandardDocuments, getSoilPollutant, getGroundWaterPollutant, getStandardPollutant, listCustomPollutant, listProject, deleteProject } from '../../apis/common'
import { connect } from 'react-redux';
import { getUser, setUser } from '../../utils/token'
import { removeSessionProductId, setSessionProductId, getSessionProductId, removeSessionLevel, removeToken, removeUser } from '../../utils/token'
import { date } from '../../configs/filterTime'
import config from '../../configs/index';
import { maximizeWindow, closeWindow, minWindow, unmaximizeWindow, showWindow } from '../../configs/electronHandle'
const { SubMenu } = Menu;
// import File from '../viewPdf/viewPdf'
// 文件下载
const file = [
  {
    title: '序号',
    dataIndex: 'ID',
    key: 'ID',
    align: 'center'
  },
  {
    title: '标准导则文件',
    dataIndex: 'Title',
    key: 'Title',
    align: 'center'
  },
  {
    title: '标准编号',
    dataIndex: 'Identifier',
    key: 'Identifier',
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: 'URL',
    key: 'URL',
    align: 'center',
    render: (record: any) => {
      return <span>
        <a style={{ marginRight: 8 }} href={record} target="_blank">查看</a>
        <a href={record} download style={{ marginRight: 8 }} target="_blank">下载</a>
      </span>;
    }
  },
]
// 地下水筛选值数据库
const groundWater = [
  {
    title: '序号',
    dataIndex: 'ID',
    key: 'ID',
    align: 'center'
  },
  {
    title: '中文名',
    dataIndex: 'NAME',
    key: 'NAME',
    align: 'center'
  },
  {
    title: 'CAS编号',
    dataIndex: 'CAS_CODE',
    key: 'CAS_CODE',
    align: 'center'
  },
  {
    title: '单位',
    dataIndex: 'UNIT',
    key: 'UNIT',
    align: 'center'
  },
  {
    title: 'III类',
    dataIndex: 'III',
    key: 'III',
    align: 'center'
  },
  {
    title: 'IV类',
    dataIndex: 'IV',
    key: 'IV',
    align: 'center'
  },
]
// 地下水筛选值数据库
const standard = [
  {
    title: '序号',
    dataIndex: 'ID',
    key: 'ID',
    fixed: 'left',
    width: 100,
    align: 'center'
  },
  {
    title: '中文名',
    dataIndex: 'NAME_CN',
    key: 'NAME_CN',
    fixed: 'left',
    width: 200,
    align: 'center'
  },
  {
    title: 'CAS编号',
    dataIndex: 'CAS_CODE',
    key: 'CAS_CODE',
    fixed: 'left',
    width: 150,
    align: 'center'
  },
  {
    title: '亨利常数(无量纲)',
    dataIndex: 'HSTRIKE',
    key: 'HSTRIKE',
    width: 200,
    align: 'center'
  },
  {
    title: '亨利常数数据来源',
    dataIndex: 'HSTRIKE_SOURCE',
    key: 'HSTRIKE_SOURCE',
    width: 200,
    align: 'center'
  },
  {
    title: 'Da(cm²/s)',
    dataIndex: 'DA',
    key: 'DA',
    width: 200,
    align: 'center'
  },
  {
    title: 'Da数据来源',
    dataIndex: 'DA_SOURCE',
    key: 'DA_SOURCE',
    width: 200,
    align: 'center'
  },
  {
    title: 'Dw(cm²/s)',
    dataIndex: 'DW',
    key: 'DW',
    width: 200,
    align: 'center'
  },
  {
    title: 'Dw数据来源',
    dataIndex: 'DW_SOURCE',
    key: 'DW_SOURCE',
    width: 200,
    align: 'center'
  },
  {
    title: 'Koc/Kd数据来源',
    dataIndex: 'ISKOC_SOURCE',
    key: 'ISKOC_SOURCE',
    width: 200,
    align: 'center'
  },
  {
    title: '溶解度(mg/L)',
    dataIndex: 'S',
    key: 'S',
    width: 200,
    align: 'center'
  },
  {
    title: '溶解度数据来源',
    dataIndex: 'S_SOURCE',
    key: 'S_SOURCE',
    width: 200,
    align: 'center'
  },
  {
    title: 'Sfo(mg/kg-d)-1',
    dataIndex: 'SFO',
    key: 'SFO',
    width: 200,
    align: 'center'
  },
  {
    title: 'Sfo数据来源)',
    dataIndex: 'SFO_SOURCE',
    key: 'SFO_SOURCE',
    width: 200,
    align: 'center'
  },
  {
    title: 'IUR(mg/m³)-1',
    dataIndex: 'IUR',
    key: 'IUR',
    width: 200,
    align: 'center'
  },
  {
    title: 'IUR数据来源',
    dataIndex: 'IUR_SOURCE',
    key: 'IUR_SOURCE',
    width: 200,
    align: 'center'
  },
  {
    title: 'RfDo(mg/kg-d)',
    dataIndex: 'RFDO',
    key: 'RFDO',
    width: 200,
    align: 'center'
  },
  {
    title: 'RfDo数据来源',
    dataIndex: 'RFDO_SOURCE',
    key: 'RFDO_SOURCE',
    width: 200,
    align: 'center'
  },
  {
    title: 'RfC(mg/m³)',
    dataIndex: 'RFC',
    key: 'RFC',
    width: 200,
    align: 'center'
  },
  {
    title: 'RfC数据来源',
    dataIndex: 'RFC_SOURCE',
    key: 'RFC_SOURCE',
    width: 200,
    align: 'center'
  },
  {
    title: 'ABSgi(无量纲)',
    dataIndex: 'ABSGI',
    key: 'ABSGI',
    width: 200,
    align: 'center'
  },
  {
    title: 'ABSgi(无量纲)数据来源',
    dataIndex: 'ABSGI_SOURCE',
    key: 'ABSGI_SOURCE',
    width: 200,
    align: 'center'
  },
  {
    title: 'ABSd(无量纲)',
    dataIndex: 'ABSD',
    key: 'ABSD',
    width: 200,
    align: 'center'
  },
  {
    title: 'ABSd(无量纲)数据来源:',
    dataIndex: 'ABSD_SOURCE',
    key: 'ABSD_SOURCE',
    width: 200,
    align: 'center'
  },
  {
    title: 'Carbon atoms per molecule(n)',
    dataIndex: 'CARBON',
    key: 'CARBON',
    width: 250,
    align: 'center'
  },
  {
    title: 'Hydrogen atoms per molecule(m)',
    dataIndex: 'HYDROGEN',
    key: 'HYDROGEN',
    width: 250,
    align: 'center'
  },
  {
    title: 'Oxygen atoms per molecule(m)',
    dataIndex: 'OXYGEN',
    key: 'OXYGEN',
    width: 250,
    align: 'center'
  },
  {
    title: 'Stoichiometric factor δ [g/g]',
    dataIndex: 'STOICHIOMETRIC',
    key: 'STOICHIOMETRIC',
    width: 250,
    align: 'center'
  },
  {
    title: '反应常数',
    dataIndex: 'KOBS',
    key: 'KOBS',
    width: 200,
    align: 'center'
  },
  {
    title: '是否VOC',
    dataIndex: 'ISVOC',
    key: 'ISVOC',
    width: 200,
    align: 'center'
  },
  {
    title: 'Kd(cm³/g)',
    dataIndex: 'KD',
    key: 'KD',
    width: 200,
    align: 'center'
  },
  {
    title: 'Koc(cm³/g)',
    dataIndex: 'KOC',
    key: 'KOC',
    width: 200,
    align: 'center'
  },
  {
    title: '是否基于Koc计算Kd',
    dataIndex: 'ISKOC',
    key: 'ISKOC',
    width: 200,
    align: 'center'
  },
]
// 土壤赛选值数据库
const pollutant = [
  {
    title: '',
    align: 'center',
    children: [
      {
        title: '序号',
        dataIndex: 'ID',
        key: 'ID',
        align: 'center'
      },
      {
        title: '中文名',
        dataIndex: 'NAME_CN',
        key: 'NAME_CN',
        align: 'center'
      },
      {
        title: 'CAS编号',
        dataIndex: 'CAS_CODE',
        key: 'CAS_CODE',
        align: 'center'
      },
    ]
  },
  {
    title: '土地筛选值(mg/kg)',
    align: 'center',
    children: [
      {
        title: '第一类用地',
        dataIndex: 'SCREEN_I',
        key: 'SCREEN_I',
        align: 'center'
      },
      {
        title: '第二类用地',
        dataIndex: 'SCREEN_II',
        key: 'SCREEN_II',
        align: 'center',
      },
    ],
  },
  {
    title: '土地管制值(mg/kg)',
    align: 'center',
    children: [
      {
        title: '第一类用地',
        dataIndex: 'CONTROL_I',
        key: 'CONTROL_I',
        align: 'center',
      },
      {
        title: '第二类用地',
        dataIndex: 'CONTROL_II',
        key: 'CONTROL_II',
        align: 'center',
      },
    ],
  }
]
export interface Props {
  props: any
}
const { TextArea } = Input;
export interface State {
  reset: boolean,
  modalvisible: boolean,
  usecenter: boolean,
  content: string,
  checkRadio: string,
  alertForm: any,
  userInfo: any,
  btnContent: string,
  btnDisabled: boolean,
  searchfile: boolean,
  columns: any,
  fileData: any,
  isScroll: any,
  isloading: boolean,
  keyword: string,
  searchKey: string,
  title: string,
  createNewProject: boolean,
  rowSelection: any,
  selectedRowKeys: any,
  placeholder: any,
}

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 7,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
};

const mapStateToProps = (state: any) => {
  return {
    userInfo: state
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  userinfoFun: () => dispatch({ type: 'USERINFO', plalod: { aaa: 1 } })
});
@(connect(mapStateToProps, mapDispatchToProps,) as any)
class ReportTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      reset: false,
      modalvisible: false,
      usecenter: false,
      content: '',
      checkRadio: '界面视觉',
      alertForm: React.createRef(),
      userInfo: {},
      btnContent: '获取验证码',
      btnDisabled: false,
      searchfile: false,
      isScroll: null,
      keyword: '',
      searchKey: '',
      isloading: true,
      title: '',
      createNewProject: false,
      rowSelection: null,
      selectedRowKeys: '',
      columns: [

      ],
      fileData: [
      ],
      placeholder: "请输入中英文名或CAS编号"
    };

  }
  gotoTap = () => {
    console.log(this.props)
  }
  setQuestionVisible = (value: boolean) => {
    this.setState({
      modalvisible: value
    })
  }
  usercenter = (value: boolean) => {
    this.setState({
      usecenter: value
    })
  }
  alertUser = () => {
    let userInfo = getUser();
    console.log("userInfo", userInfo)
    this.usercenter(true)
    setTimeout(() => {
      this.state.alertForm.current.setFieldsValue(userInfo)
    }, 100)
  }
  confirmSubmit = async () => {
    let formValue = this.state.alertForm.current.getFieldsValue()
    let {
      account,
      email_address,
      work_address,
      phone,
      verifyCode,
    } = formValue
    let params = {
      account,
      email_address,
      work_address,
      phone,
      verifyCode
    };
    let res = await updateInfo(params);
    if (res.code == 0) {
      setUser(res.data)
    }
    this.setState({
      usecenter: false
    })
  }
  closewindows = () => {
    removeSessionLevel();
    removeSessionProductId();
    removeToken();
    removeUser()
    setTimeout(() => {
      this.props.props.history.push({
        pathname: "/login"
      })
    })
    closeWindow()
  }
  sendMessage = async () => {
    let params = {
      type: this.state.checkRadio,
      content: this.state.content
    }
    let data = await submitFeedBack(params);
    if (data.code == '0') {
      message.success({
        content: '反馈成功！',
        className: 'custom-class',
        style: {
          marginTop: '40vh',
        },
      });
      removeSessionLevel();
      removeSessionProductId();
      removeToken();
      removeUser()
      setTimeout(() => {
        this.props.props.history.push({
          pathname: "/login"
        })
      })
      closeWindow()
    } else {
      message.success({
        content: '接口提交异常！',
        className: 'custom-class',
        style: {
          marginTop: '40vh',
        },
      });
    }
    this.setState({
      modalvisible: false
    })
  }
  onChange = (e: any) => {
    let value = e.target.value;
    this.setState({
      content: value
    })
  };
  checkRadio = (e: any) => {
    let value = e.target.value;
    let content = ''
    switch (value) {
      case 1:
        content = '界面视觉'
        break;
      case 2:
        content = '功能'
        break;
      case 3:
        content = '出错'
        break;
      case 4:
        content = '其他'
        break;
      default:
        break;
    }
    this.setState({
      checkRadio: content
    })
  }
  // 获取手机验证码
  getCaptcha = async () => {
    let phoneNumber = this.state.alertForm.current.getFieldsValue().phone
    console.log(phoneNumber)
    if (!phoneNumber) {
      message.warning('请先输入手机号');
      return
    }
    await getVerifyCode({
      "phone": phoneNumber,
      "dev": 0,
      "type": 3
    }).then((res: any) => {
      this.cutdown(60)
      message.success('发送成功');
    }).catch((err: any) => {
    })
  }
  cutdown = (time: number) => {
    let that = this
    if (time > 1) {
      time--
      this.setState({
        btnContent: `${time}s`,
        btnDisabled: true
      })
      setTimeout(function () {
        that.cutdown(time)
      }, 1000)
    } else {
      this.setState({
        btnContent: `获取验证码`,
        btnDisabled: false
      })
    }
  }
  searchMethod = (key: any) => {
    this.clearStatus()
    switch (key) {
      case '1':
        this.getSoilPollutant()
        break;
      case '2':
        this.getGroundWaterPollutant()
        break;
      case '3':
        this.getStandardPollutant()
        break;
      case '4':
        this.listCustomPollutant()
        break;
      case '5':
        this.getStandardDocuments()
        break;
      case '6':
        this.listProject()
        break;
      default:
        break;
    }
    this.setState({
      searchKey: key
    })
    this.searchfileVisible(true)
  }
  clearStatus = () => {
    this.setState({
      columns: [],
      fileData: [],
      isScroll: null,
      keyword: '',
      searchKey: '',
      isloading: true,
      title: '',
      rowSelection: null,
      placeholder: ''
    })
  }
  searchfileVisible = (value: any) => {
    this.setState({ searchfile: value })
    if (!value) {
      this.clearStatus()
    }
    if (this.state.selectedRowKeys) {
      removeSessionProductId()
      removeSessionLevel()
      setSessionProductId(this.state.selectedRowKeys);
      setTimeout(() => {
        this.props.props.history.push({
          pathname: "/project/info"
        })
        window.location.reload()
      }, 200)
      // console.log(this.props.props)
      // this.props.props
      console.log(this.props.props)
    }
  }
  // 获取标准文件
  getStandardDocuments = async () => {
    let res = await getStandardDocuments({
      page: 1,
      size: 100000
    });
    this.setState({
      columns: file,
      fileData: res.data,
      isScroll: null,
      isloading: false,
      title: '标准导则文件查询',
      rowSelection: null,
      placeholder: ""
    })
  }
  // 土壤筛选
  getSoilPollutant = async () => {
    let res = await getSoilPollutant({
      page: 1,
      size: 100000,
      keyword: this.state.keyword
    });
    this.setState({
      columns: pollutant,
      fileData: res.data,
      isScroll: null,
      isloading: false,
      title: '土壤筛选值数据库',
      rowSelection: null,
      placeholder: "请输入中英文名或CAS编号"
    })
  }
  // 地下水筛选
  getGroundWaterPollutant = async () => {
    let res = await getGroundWaterPollutant({
      page: 1,
      size: 100000,
      keyword: this.state.keyword
    });
    this.setState({
      columns: groundWater,
      fileData: res.data,
      isScroll: null,
      isloading: false,
      title: '地下水筛选值数据库',
      rowSelection: null,
      placeholder: "请输入中英文名或CAS编号"
    })
  }
  // 标准污染物数据库
  getStandardPollutant = async () => {
    let res = await getStandardPollutant({
      page: 1,
      size: 100000,
      keyword: this.state.keyword
    });
    this.setState({
      columns: standard,
      fileData: res.data,
      isScroll: { x: 'calc(1700px + 50%)' },
      isloading: false,
      title: '标准污染物数据库',
      rowSelection: null,
      placeholder: "请输入中英文名或CAS编号"
    })
  }
  // 自定义污染物数据库
  listCustomPollutant = async () => {
    let res = await listCustomPollutant({
      page: 1,
      size: 10000,
      keyword: this.state.keyword
    });
    this.setState({
      columns: standard,
      fileData: res.data,
      isScroll: { x: 'calc(1700px + 50%)' },
      isloading: false,
      title: '自定义污染物数据库',
      rowSelection: null,
      placeholder: "请输入中英文名或CAS编号"
    })
  }


  // 自定义污染物数据库
  listProject = async () => {
    let res = await listProject({
      page: 1,
      size: 10000,
      keyword: this.state.keyword
    });
    let list = res.data;
    list.forEach((value: any, index: any) => {
      if (value.project_type == 1) {
        list[index].project_type = '住宅用地'
      } else {
        list[index].project_type = '工业用地'
      }
      list[index].address = `${value.province}${value.city}${value.address}`
      list[index].project_time = date("Y-m-d", parseInt(value.project_time))
      list[index].key = index + 1
    });
    let that = this
    // 创建项目列表
    const listPro = [
      {
        title: '项目名称',
        dataIndex: 'project_name',
        key: 'project_name',
        align: 'center',
      },
      {
        title: '项目地点',
        dataIndex: 'address',
        key: 'address',
        align: 'center',
      },
      {
        title: '用地类型',
        dataIndex: 'project_type',
        key: 'project_type',
        align: 'center',
      },
      {
        title: '日期',
        dataIndex: 'project_time',
        key: 'project_time',
        align: 'center',
      },
      {
        title: '操作',
        dataIndex: 'URL',
        key: 'URL',
        align: 'center',
        render: (text: any, record: any, index: any) => {
          return <span>
            <Popconfirm
              title="确认删除此项？"
              onConfirm={this.deleteProject.bind(this, record)}
              okText='确定'
              cancelText='取消'
            >
              <a>删除</a>
            </Popconfirm>
          </span>;
        }
      },
    ]
    this.setState({
      columns: listPro,
      fileData: list,
      isScroll: null,
      isloading: false,
      title: '打开项目',
      rowSelection: true,
      placeholder: '请输入项目名称'
    })
  }
  deleteProject = async (value: any) => {
    console.log(value.id)
    let ProductId = getSessionProductId()
    let params = {
      delete_ids: JSON.stringify(value.id)
    }
    try {
      let res = await deleteProject(params);
      if (res.code == '0') {
        this.listProject()
        if (ProductId == value.id) {
          removeSessionProductId()
          removeSessionLevel()
          setTimeout(() => {
            this.props.props.history.push({
              pathname: "/project/info"
            })
            window.location.reload()
          }, 200)
        }
      }
    } catch (error) {

    }
  }
  searchTable = () => {
    let searchKey = this.state.searchKey;
    switch (searchKey) {
      case '1':
        this.getSoilPollutant()
        break;
      case '2':
        this.getGroundWaterPollutant()
        break;
      case '3':
        this.getStandardPollutant()
        break;
      case '4':
        this.listCustomPollutant()
        break;
      case '5':
        this.getStandardDocuments()
        break;
      case '6':
        this.listProject()
        break;
      default:
        break;
    }
  }
  getChange = (i: any) => {
    this.setState({
      keyword: i.target.value
    })
    if (!i.target.value) {
      setTimeout(() => {
        this.searchTable()
      }, 500)
    }
  }
  newProject = (i: any) => {
    switch (i) {
      case '1':
        this.createNewProject(true)
        break;
      case '2':

        break;
      default:
        break;
    }
  }
  handleCreate = () => {
    removeSessionProductId()
    removeSessionLevel()
    this.createNewProject(false)
    setTimeout(() => {
      this.props.props.history.push({
        pathname: "/project/info"
      })
      window.location.reload()
    }, 200)
  }
  setWindows = (value: any) => {
    if (value == 'max') {
      maximizeWindow()
    } else {
      unmaximizeWindow()
    }
    this.setState({
      reset: !this.state.reset
    })
  }
  createNewProject = (value: boolean) => {
    this.setState({
      createNewProject: value
    })
  }
  doubeClick = () => {
    showWindow()
  }
  componentDidMount() {

  }
  render() {
    let dataBaseMenu = (
      <Menu>
        <SubMenu title="数据库查询">
          <Menu.Item onClick={() => this.searchMethod('1')}>土壤筛选值数据库查询</Menu.Item>
          <Menu.Item onClick={() => this.searchMethod('2')}>地下水筛选值数据库查询</Menu.Item>
          <Menu.Item onClick={() => this.searchMethod('3')}>标准污染物数据库查询</Menu.Item>
          <Menu.Item onClick={() => this.searchMethod('4')}>自定义污染物数据库查询</Menu.Item>
        </SubMenu>
        <SubMenu title="政策/文件查询">
          <Menu.Item onClick={() => this.searchMethod('5')}>标准导则文件查询</Menu.Item>
        </SubMenu>
      </Menu>
    )
    const fileMenu = (
      <Menu className="new_File">
        <Menu.Item onClick={() => this.newProject('1')}>
          {/* <img src={require("../../assets/images/homePage/addFile.png").default}></img> */}
          新建
        </Menu.Item>
        <Menu.Item onClick={() => this.searchMethod('6')}>
          {/* <img src={require("../../assets/images/homePage/openFile.png").default}></img> */}
          打开
        </Menu.Item>
      </Menu>
    )
    const rowSelection = {
      type: 'radio',
      onChange: (selectedRowKeys: any, selectedRows: any) => {
        this.setState({
          selectedRowKeys: selectedRows[0].id
        })
      }
    };
    return (
      <div className="db-header win-drag" onDoubleClick={this.doubeClick}>
        <div className="db-header-logo">
          <img
            src={require("../../assets/images/homePage/logo.png").default}
            alt=""
            draggable="false"
          ></img>
        </div>
        <div>
          <div className="db-header-bg">污染场地风险评估系统</div>
          {/* <div className="db-header-sm">测试版</div> */}
        </div>
        <div className="login-space"></div>
        <div className="homepage-system-container win-no-drag">
          <Space direction="horizontal">
            <Space wrap>
              <Dropdown overlay={fileMenu} placement="bottomCenter" arrow overlayClassName='resetOverlay'>
                <div className="headbettwen">
                  <img className="headeImg" src={require('../../assets/images/headTop/info.png').default} ></img>
                  <span className="headerColor">文件</span>
                </div>
              </Dropdown>
              <Dropdown overlay={dataBaseMenu} placement="bottomCenter" arrow overlayClassName='resetOverlay'>
                <div className="headbettwen">
                  <img className="headeImg" src={require('../../assets/images/headTop/data.png').default}></img>
                  <span className="headerColor">数据库查询</span>
                </div>
              </Dropdown>
              <div onClick={() => this.alertUser()} className="headbettwen" >
                <img className="headeImg" src={require('../../assets/images/headTop/center.png').default}></img>
                <span className="headerColor">个人中心</span>
              </div>
            </Space>
          </Space>
        </div>
        {/* <div className="homepage-screen-control-container win-no-drag">
                <img src={require("../../assets/images/homePage/min.png").default} onClick={minWindow} className="pointerhand"></img>
                {this.state.reset ? <img src={require("../../assets/images/homePage/max.png").default} onClick={()=>this.setWindows('max')} className="pointerhand"></img> : <img src={require("../../assets/images/homePage/reset.png").default} onClick={()=>this.setWindows('reset')} className="pointerhand"/>}
                <img onClick={() => this.closewindows()} src={require("../../assets/images/homePage/close.png").default} className="pointerhand"></img>
            </div> */}
        <Modal
          title="问题反馈"
          centered
          visible={this.state.modalvisible}
          onOk={() => this.sendMessage()}
          onCancel={() => this.setQuestionVisible(false)}
          width={800}
          okText="提交反馈"
          cancelText="关闭"
          destroyOnClose={true}
        >
          <div className="isheader">
            <div className="questionbettwen">
              <div className="questionbleaft">
                <div className="questiontitle">欢迎使用污染场地风险评估软件，我们真诚希望听取您的意见和建议！</div>
                <div className="questionemaid">
                  <span className="email">E-mail：<span className="emailnum">197973229@qq.com</span></span>
                  <span className="email">QQ：<span className="emailnum">197973229</span></span>
                </div>
              </div>
              <div className="questionbright">
                <img src={require('../../assets/images/headTop/question.png').default}></img>
              </div>
            </div>
            <div className="fankui">
              <div className="fankuititle">反馈内容关于</div>
              <Radio.Group name="radiogroup" defaultValue={1} onChange={(e) => this.checkRadio(e)}>
                <Radio value={1}>界面视觉</Radio>
                <Radio value={2}>功能</Radio>
                <Radio value={3}>出错</Radio>
                <Radio value={4}>其他</Radio>
              </Radio.Group>
            </div>
            <div className="fankui twotop">
              <div className="fankuititle">反馈内容</div>
              <TextArea showCount maxLength={500} onChange={this.onChange} />
            </div>
          </div>
        </Modal>
        <Modal
          title="个人中心"
          centered
          visible={this.state.usecenter}
          onOk={() => this.confirmSubmit()}
          onCancel={() => this.usercenter(false)}
          width={800}
          okText="保存"
          cancelText="取消"
          destroyOnClose={true}
        >
          <Form
            {...formItemLayout}
            name="register"
            ref={this.state.alertForm}
          >
            <Form.Item
              name="account"
              label="用户名"
              rules={[
                {
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="work_address"
              label="工作单位"
              rules={[
                {
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="email_address"
              label="邮箱地址"
              rules={[
                {
                  type: 'email',
                  message: '邮箱账号不正确',
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="手机号码" className="Formbtn">
              <Row gutter={8}>
                <Col span={10}>
                  <Form.Item
                    name="phone"
                    noStyle
                    rules={[
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item
                    name="verifyCode"
                    noStyle
                  >
                    <Input placeholder="输入验证码" />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Button disabled={this.state.btnDisabled} onClick={() => this.getCaptcha()}>{this.state.btnContent}</Button>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              name="is_active"
              label="激活状态"
              rules={[
                {
                  whitespace: true,
                },
              ]}
            >
              <Input disabled placeholder={this.state.userInfo.is_active ? '已激活' : '未激活'} />
            </Form.Item>
            {/* <Form.Item
                name="openid"
                label="账号绑定"
                rules={[
                  {
                    whitespace: true,
                  },
                ]}
              >
              <div className="account">
                <img src={require('../../assets/images/headTop/wechat.png').default} className="accountimg"></img>
                {
                  this.state.userInfo.openid?(
                    <div className="yibangding">已绑定</div>
                  ):(<div className="bangding" >去绑定</div>)
                }
              </div>
            </Form.Item> */}
          </Form>
        </Modal>
        <Modal
          title={this.state.title}
          visible={this.state.searchfile}
          onOk={() => this.searchfileVisible(false)}
          onCancel={() => this.searchfileVisible(false)}
          cancelText="取消"
          okText="确定"
          width={1000}
          destroyOnClose={true}
        >
          {this.state.placeholder ? (
            <div>

              <Space>
                <Input placeholder={this.state.placeholder} size="large" allowClear={true} onChange={this.getChange} />
                <Button type="primary" onClick={this.searchTable} >
                  搜索
                </Button>
              </Space>
              <Divider />
            </div>
          ) : ''}
          <Table
            bordered
            columns={this.state.columns}
            dataSource={this.state.fileData}
            pagination={{ pageSize: 8 }}
            size="small"
            scroll={this.state.isScroll}
            loading={this.state.isloading}
            rowKey={record => record.key || record.ID}
            rowSelection={this.state.rowSelection && rowSelection}
          />
        </Modal>
        <Modal
          visible={this.state.createNewProject}
          onOk={this.handleCreate}
          onCancel={() => this.createNewProject(false)}
          okText="新建"
          cancelText="取消"
          centered
        >
          是否创建新的项目？
        </Modal>
      </div>
    );
  }
}

export default ReportTable;
