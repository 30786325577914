import * as React from "react";
import { Select,Tabs, Table,Row, Col, Form ,message} from "antd";

import "./reportTable.less";
export interface Props {
  dataSource:any,
  columns:any
}

export interface State {
  // dataSource:any,
  // columns:any
}

class ReportTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      // dataSource:[],
      // columns:[]
    };
  }

  render() {
    return (
      <table className="tftable">
        <thead>
          <tr>
            {
              this.props.columns.map((value:any,index:any)=>{
                return <th key={index}>{value.title}</th>
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            this.props.dataSource.map((value:any,d:any)=>{
              return <tr key={d}>
                      {
                        this.props.columns.map((i:any,index:any)=>{
                          return <td key={index} style={(i.isColor&&(value.color != '#000000'))?{ color: '#fff', backgroundColor:value.color,fontWeight:'bold'}:{ }}>{value[i.dataIndex]}</td>
                        })
                      }
                    </tr>
            })
          }
        </tbody>
      </table>
          
    );
  }
}

export default ReportTable;
