import {IRoute,system,project} from './config';
function getBusinessRouteList(): IRoute[] {
  return project
}

function getSystemRouteList(): IRoute[] {
  return system
}

/**
 * 这里会将 config 中所有路由解析成三个数组
 * 第一个: 最外层的路由，例如  Layout UserLayout ...
 * 第二个: 系统路由, 例如 Login Register RegisterResult
 * 第三个: 业务路由，为 / 路由下的业务路由
 */

export const businessRouteList = getBusinessRouteList();

export const systemRouteList = getSystemRouteList();


export function getPageTitle(routeList: IRoute[]): string {
  const route = routeList.find(child => child.path === window.location.pathname);

  return route ? route.meta.title : '';
}

