import * as React from "react";
import { Select,Tabs, Row, Col, Form ,message,Spin} from "antd";
import {getModelStatus,saveModelStatus} from '../../../apis/common'
import {checkProductd} from '../../../configs/checkProductId.js'
import "./exposeRoad.less";

const { TabPane } = Tabs;
const baseurl = '../../../assets/images/exposeRoad/';
// expose1.png
export function loadImageUrl(id:number){
  // let a=require(`${baseurl}expose${id}.png`);
  // console.log(a)
  const iconUrl = require(`${baseurl}expose${id}.png`)
  return iconUrl
}

export interface Props {
  history:any
}

export interface State {
  soilData:any,
  waterData:any,
  project_id:any,
  soil:any,
  water:any,
  loading:boolean
}

class exposeRoad extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      soilData:[
        {
          srcImage:'expose1.png',
          title:'经口摄入土壤颗粒物',
          options:[{
            name:'HJ25.3评估模型'
          }],
          clickActive:false
        },
        {
          srcImage:'expose2.png',
          title:'皮肤接触土壤颗粒物',
          options:[{
            name:'HJ25.3评估模型'
          }],
          clickActive:false
        },
        {
          srcImage:'expose3.png',
          title:'吸入土壤污染物',
          options:[{
            name:'HJ25.3评估模型'
          }],
          clickActive:false
        },
        {
          srcImage:'expose4.png',
          title:'吸入室外空气中来自表层土壤的气态污染物',
          options:[{
            name:'HJ25.3评估模型'
          }],
          clickActive:false
        },
        {
          srcImage:'expose5.png',
          title:'吸入室外空气中来自下层土壤的气态污染物',
          options:[{
            name:'HJ25.3评估模型'
          }],
          clickActive:false
        },
        {
          srcImage:'expose6.png',
          title:'吸入室内空气中来自下层土壤的气态污染物',
          options:[{
            name:'HJ25.3评估模型'
          }],
          clickActive:false
        }
      ],
      waterData:[
        {
          srcImage:'water1.png',
          title:'吸入室外空气中来自地下水的气态污染物',
          options:[{
            name:'HJ25.3评估模型'
          }],
          clickActive:false
        },
        {
          srcImage:'water2.png',
          title:'吸入室内空气中来自地下水的气态污染物',
          options:[{
            name:'HJ25.3评估模型'
          }],
          clickActive:false
        },
        {
          srcImage:'water3.png',
          title:'饮用地下水',
          options:[{
            name:'HJ25.3评估模型'
          }],
          clickActive:false
        },
      ],
      soil:[],
      water:[],
      loading:true,
      project_id:''
    };
  }

  changeStatus=(value:any)=>{
    let [i,active,type]= value;
    if(type == 'soil'){
      let items =  this.state.soilData;
      let soil =  this.state.soil;
      items[i].clickActive = !active;
      soil[i].selected = Number(!active);
      this.setState({
        soilData:items,
        soil
      });
    }else{
      let items =  this.state.waterData;
      let water =  this.state.water;
      items[i].clickActive = !active;
      water[i].selected = Number(!active);
      this.setState({
        waterData:items,
        water
      }); 
    }
  }
  gotoTap= async(step:string)=>{
    if(step == 'back'){
      this.props.history.push({
        pathname:"/project/followPollute"
      })
      return
    }
    if(step == 'next'){
      console.log(this.state.water,'==')
      let isNextTab = false
      this.state.water.forEach((ele:any) => {
        if(ele.selected == '1'){
          isNextTab = true
        }
      });
      this.state.soil.forEach((ele:any) => {
        if(ele.selected == '1'){
          isNextTab = true
        }
      });
      if(!isNextTab) {
        message.info('至少选择一个！')
        return
      }
      try {
        let params = {
          project_id:this.state.project_id,
          data:JSON.stringify({
            water:this.state.water,
            soil:this.state.soil
          })
        }
        let res = await saveModelStatus(params)
        if(res.code =='0'){
          this.props.history.push({
            pathname:"/project/parmas"
          })
        }
      } catch (error) {
      }
    }
  }
  componentWillMount(){
    let productId=checkProductd();
    if(productId){
      this.setState({
        project_id:productId
      })
    }
  }
  componentDidMount(){
    this.getModelStatus()
  }
  getModelStatus=async()=>{
    let soilData=this.state.soilData;
    let waterData=this.state.waterData;
    await getModelStatus({
      project_id:this.state.project_id
    }).then((res:any)=>{
      // console.log(res.data.soil[0].through_mouth.selected)
      let soil = res.data.soil;
      let water = res.data.water;
      soilData.map((value:any,i:any)=>{
        soil.map((values:any,d:any)=>{
          if(i==d){
            soilData[i].clickActive= values.selected
          }
        })
      })
      waterData.map((value:any,i:any)=>{
        water.map((values:any,d:any)=>{
          if(i==d){
            waterData[i].clickActive= values.selected
          }
        })
      })
      this.setState({
        soilData,
        waterData,
        soil,
        water,
        loading:false
      })
    }).catch(()=>{

    })
  }
  render() {
    return (
      <div className="ps-content">
        <Spin spinning={this.state.loading}>
        <Tabs
          defaultActiveKey="1"
          type="card"
          onChange={(key) => {
            console.log(key);
          }}
        >
          <TabPane tab="土壤污染物暴露途径" key="1">
            <div className="exposeContent">
              <Row gutter={[60, 53]}>
                {
                  this.state.soilData.map((item:any,index:any)=>{
                    return <Col span={8} key={index}>
                      <div onClick={this.changeStatus.bind(this,[index,item.clickActive,'soil'])} className={`${item.clickActive?'exposeactive':'exposeRoad'}`}>
                        <img src={require(`../../../assets/images/exposeRoad/${item.srcImage}`).default} className="widthImg"></img>
                        <img src={require(`../../../assets/images/exposeRoad/active.png`).default} style={{visibility:item.clickActive?'visible':'hidden'}} className="activeImg"/> 
                        <div className="exposeTitle">{item.title}</div>
                          <Form.Item name="gender" label="模型">
                              <Select defaultValue={item.options[0].name} style={{ width: 200 }}>
                                  { 
                                    item.options.map((value:any,d:any)=>{
                                      return <Select.Option value="male" key={d}>{value.name}</Select.Option>
                                    })
                                  }
                              </Select>
                          </Form.Item>
                      </div>
                    </Col>
                  })
                }
              </Row>
            </div>
         </TabPane>
          <TabPane tab="地下水污染物暴露途径" key="2">
            <div className="exposeContent">
              <Row gutter={[60, 53]}>
                {
                  this.state.waterData.map((item:any,index:any)=>{
                    return <Col span={8} key={index}>
                      <div onClick={this.changeStatus.bind(this,[index,item.clickActive,'water'])} className={`${item.clickActive?'exposeactive':'exposeRoad'}`}>   
                        <img src={require(`../../../assets/images/exposeRoad/${item.srcImage}`).default} className="widthImg"></img>
                        <img src={require(`../../../assets/images/exposeRoad/active.png`).default} style={{visibility:item.clickActive?'visible':'hidden'}} className="activeImg"/> 
                        <div className="exposeTitle">{item.title}</div>
                          <Form.Item name="gender" label="模型">
                              <Select defaultValue={item.options[0].name} style={{ width: 200 }}>
                                  { 
                                    item.options.map((value:any,d:any)=>{
                                      return <Select.Option value="male" key={d}>{value.name}</Select.Option>
                                    })
                                  }
                              </Select>
                          </Form.Item>
                      </div>
                    </Col>
                  })
                }
              </Row>
            </div>
          </TabPane>
        </Tabs>
        <div className="exposebtn">
          <button className="formbtnleft" onClick={this.gotoTap.bind(this,'back')}>上一步</button>
          <button className="formbtnright" onClick={this.gotoTap.bind(this,'next')}>下一步</button>
        </div>
        </Spin>
      </div>
    );
  }
}

export default exposeRoad;
