function stringify(value: any): string {
  return JSON.stringify(value);
}

function parse<T>(value: string): T | null {
  try {
    return JSON.parse(value) as T;
  } catch (error) {
    return null;
  }
}

interface ISessionStore {
  setSessionValue(key: string, data: any): ISessionStore;
  getSessionValue<T>(key: string, defaultValue?: T): T | null;
  removeSessionValue(key: string): ISessionStore;
}

const SessionStore: ISessionStore = {
  setSessionValue(key: string, data: any): ISessionStore {
    sessionStorage.setItem(key, stringify(data));
    return this;
  },
  getSessionValue<T>(key: string, defaultValue?: T): T | null {
    const value = sessionStorage.getItem(key);

    if (!value) return defaultValue || null;
    const data = parse<T>(value);
    return data;
  },
  removeSessionValue(key: string): ISessionStore {
    sessionStorage.removeItem(key);
    return this;
  },
};

export default SessionStore;
