
import {setSessionProductId,getSessionProductId,removeSessionProductId} from '../utils/token'
import config from '../configs/index';
export const saveProductId = (productId) => {
    setSessionProductId(productId)  
}


export const checkProductd = () => {
     let productId = getSessionProductId();
     console.log("productId1111",productId);
     if(productId){
         return productId
     }else{
        window.location.href = `${
            window.location.origin
          }${config.BASENAME}/project/info`;
        return false
     }
}
