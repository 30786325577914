import * as React from "react";
import { withRouter } from "react-router-dom";
import { levelAnalysis,getProjectInfo} from "../../apis/common";
import {  message,Row,Col,Spin } from "antd";
import {checkProductd} from '../../configs/checkProductId.js'
import {setSessionLevel } from '../../utils/token' 
import './projectAnalysis.less'
import eventBus from '../../configs/events';
interface IProps {
  login: any;
  register: any;
  history: any;
  retrieve: any;
  location:any;
}

interface IState {
  clickoneActive: boolean,
  clicktwoActive: boolean,
  project_id:any,
  loading:boolean
}

class analysis extends React.PureComponent<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      clickoneActive: false,
      clicktwoActive: false,
      project_id:'',
      loading:true
    };
  }
  changeStatus=(value:any)=>{
    let mycheck = {}
    if(value ==='first'){
      this.setState({
        clickoneActive:!this.state.clickoneActive,
        clicktwoActive:false
       });
    }else if(value ==='two'){
      this.setState({
        clicktwoActive:!this.state.clicktwoActive,
        clickoneActive:false
      });
    }
    setTimeout(()=>{
      mycheck={
        clicktwoActive:this.state.clicktwoActive,
        clickoneActive:this.state.clickoneActive
       }
      eventBus.emit('changeTabSelect',mycheck);
    },50)
  }
  gotoTap= async(step:string)=>{
    if(!this.state.project_id){
      message.info('请先创建项目！');
      setTimeout(()=>{
        this.props.history.push({
          pathname:"/project/info"
        })
      },2000)
      return
    }
    if(step == 'back'){
      this.props.history.push({
        pathname:"/project/info"
      })
      return
    }
    if(!this.state.clickoneActive&&!this.state.clicktwoActive){
      message.info('至少选择一项');
      return
    }
    let level = this.state.clickoneActive?'1':'2';
    await levelAnalysis({    
      level,
      project_id:this.state.project_id
    }).then((res:any)=>{
      setSessionLevel(level)
      this.props.history.push({
        pathname:"/project/followPollute"
      })
    }).catch((err:any)=>{
      message.warn('服务器异常，请稍后再试')
    })
  }
 getProjectInfoData = async(project_id:any)=>{
    let res =  await getProjectInfo({project_id});
    let select = res.data.level.toString()=='1'? 'first':'two'
    this.changeStatus(select)
    this.setState({
      loading:false
    })
   }
   componentWillMount(){
    let productId=checkProductd();
    if(productId){
      this.setState({
        project_id:productId
      })
    }
  }
  componentDidMount(){
    let productId = this.state.project_id;
    if(productId){
      this.getProjectInfoData(productId);
    }
  }
  render() {
    return (
      <div className="content">
        <Spin spinning={this.state.loading}>
        <Row>
          <Col  xs={{ span: 24, offset: 0 }} lg={{ span: 18, offset: 2 }}>
            <div className={`analysis ${this.state.clickoneActive?'oneactive':'onecommon'}`} onClick={this.changeStatus.bind(this,'first')}>
              {this.state.clickoneActive ? <img src={require("../../assets/images/projectAnalysis/timeoneactive.png").default}></img> : <img src={require("../../assets/images/projectAnalysis/timeone.png").default}/>}
              <div className="analysis-left">
                <div className="left-title">第一层次（Tier 1 ）</div>
                <div className="left-content">针对不同用地方式，分别比对不同污染物是否超过土壤筛选值和地下水筛选值。</div>
              </div>
            </div>
          </Col>
          <Col xs={{ span: 24, offset: 0 }} lg={{ span: 18, offset: 2 }}>
            <div className={`analysis ${this.state.clicktwoActive?'twoactive':'twocommon'}`} onClick={this.changeStatus.bind(this,'two')}>
              {this.state.clicktwoActive ? <img src={require("../../assets/images/projectAnalysis/timetwoactive.png").default}></img> : <img src={require("../../assets/images/projectAnalysis/timetwo.png").default}/>}
              <div className="analysis-left">
                <div className="left-title">第二层次（Tier 2 ）</div>
                <div className="left-content">工作内容包括危害识别、暴露评估、毒性评估、风险表征，以及土壤和地下水风险控制值的计算，可选择不同的暴露途径及
场地特征参数，评估体系更加完善。</div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="exposebtn">
            <button className="formbtnleft" onClick={this.gotoTap.bind(this,'back')}>上一步</button>
            <button className="formbtnright" onClick={this.gotoTap.bind(this,'next')}>下一步</button>
        </div>
        </Spin>
      </div>
    );
  }
}

export default withRouter(analysis);
