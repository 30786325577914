import * as React from "react";
import { Layout, Menu,Tree } from "antd";
import { withRouter, Switch} from "react-router-dom";
import BreadCrumb from "../components/common/breadCrumb"
import HeaderTop from '../components/headerTop/headerTop'
import "../assets/styles/homepage.less";
import MainRoutes from '../layout/MainRoutes';
import BasicInfoForm from '../components/basicInfoForm';
import {getSessionLevel} from '../utils/token'
import eventBus from '../configs/events';
import {showWindow} from '../configs/electronHandle'
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;




interface HomePageProps {
    location: any,
    history:any
}

interface HomePageState {
    reset: boolean,
    expandedKeys:any,
    treeData:any,
    defaultExpandAll:boolean,
    level:string
}


const fileMenu = (
    <Menu>
        <Menu.Item>
            <img src={require("../assets/images/homePage/addFile.png").default}></img>
            新建
        </Menu.Item>
        <Menu.Item>
            <img src={require("../assets/images/homePage/openFile.png").default}></img>
            打开
        </Menu.Item>
        <Menu.Item>
            <img src={require("../assets/images/homePage/saveFile.png").default}></img>
            保存
        </Menu.Item>
        <Menu.Item>
            <img src={require("../assets/images/homePage/saveFileAs.png").default}></img>
            另存为
        </Menu.Item>
        <Menu.Item>
            <img src={require("../assets/images/homePage/exportFile.png").default}></img>
            导入
        </Menu.Item>
    </Menu>
)

const dataBaseMenu = (
    <Menu>
        <Menu.Item>
            土壤筛选值数据库查询
        </Menu.Item>
        <Menu.Item>
            地下水筛选值数据库查询
        </Menu.Item>
        <Menu.Item>
            标准污染物数据库查询
        </Menu.Item>
        <Menu.Item>
            自定义污染物数据库查询
        </Menu.Item>
        <Menu.Item>
            更新数据库
        </Menu.Item>
    </Menu>
)
const urlpatterns = [
  {
    url:'/project/info',
    keys:'0',
    breadtitle:'项目信息',
  },
  {
    url:'/project/analysis',
    keys:'1',
    breadtitle:'层次分析',
  },
  {
    url:'/project/followPollute',
    keys:'2-1',
    breadtitle:'项目输入/关注污染物',
  },
  {
    url:'/project/exposeRoad',
    keys:'2-2',
    breadtitle:'项目输入/暴露途径及模型',
  },
  {
    url: '/project/parmas',
    keys:'2-3',
    breadtitle:'项目输入/参数设置',
  },
  {
    url:'/project/conceptModel',
    keys:'3-1',
    breadtitle:'项目输出/概念模型',
  },
  {
    url:'/project/polluteParamsTable',
    keys:'3-2',
    breadtitle:'项目输出/污染物参数表',
  },
  {
    url: '/project/modelParamsTable',
    keys:'3-3',
    breadtitle:'项目输出/模型及参数表',
  },
  {
    url: '/project/exposedQuantityTable',
    keys:'3-4',
    breadtitle:'项目输出/暴露量计算',
  },
  {
    url:'/project/riskCalculation',
    keys:'3-5',
    breadtitle:'项目输出/风险计算',
  },
  {
    url:'/project/ContributionRateCalculation',
    keys:'3-6',
    breadtitle:'项目输出/贡献率计算',
  },
  {
    url: '/project/controlValueCalculation',
    keys:'3-7',
    breadtitle:'项目输出/控制值计算',
  },
  {
    url:'/project/generalReport',
    keys:'3-8',
    breadtitle:'项目输出/总报告',
  }


]
const morenTreeData = [
  {
    title: '项目信息',
    key: '0',
  },
  {
    title: '分析层次',
    key: '1',
  },
  {
    title: '项目输入',
    key: '2',
  },
  {
    title: '项目输出',
    key: '3'

  },
];
const firstTreeData = [
  {
    title: '项目信息',
    key: '0',
  },
  {
    title: '分析层次',
    key: '1',
  },
  {
    title: '项目输入',
    key: '2',
    children: [
      { title: '关注污染物', key: '2-1' }
    ],
  },
  {
    title: '项目输出',
    key: '3',
    children: [
      { title: '总报告', key: '3-6' },
    ],

  },
];
const twoTreeData = [
  {
    title: '项目信息',
    key: '0',
  },
  {
    title: '分析层次',
    key: '1',
  },
  {
    title: '项目输入',
    key: '2',
    children: [
      { title: '关注污染物', key: '2-1' },
      { title: '暴露途径及模型', key: '2-2' },
      { title: '参数设置', key: '2-3' },
    ],
  },
  {
    title: '项目输出',
    key: '3',
    children: [
      { title: '概念模型', key: '3-1' },
      { title: '污染物参数表', key: '3-2' },
      { title: '模型及参数表', key: '3-3' },
      { title: '暴露量计算', key: '3-4' },
      { title: '风险计算', key: '3-5' },
      { title: '贡献率计算', key: '3-6' },
      { title: '控制值计算', key: '3-7' },
      { title: '总报告', key: '3-8' },
    ],

  },
];
class HomePage extends React.PureComponent<HomePageProps, HomePageState> {
  constructor(props: HomePageProps) {
    super(props);
    this.state = {
        reset: false,
        expandedKeys:[],
        treeData:[],
        defaultExpandAll:false,
        level:'',
    };
    this.props.history.listen((route:any) => {
      this.handleSelectTab(route, ' 路由监听')
    });
  }
  componentWillMount(){
    let route =this.props.history.location;
    this.handleSelectTab(route, ' 传参')
    eventBus.on('changeTabSelect', this.handleSayHelloListener);
    this.getStatusLevel()
  }
  componentDidMount(){
    // maximizeWindow()
    showWindow()
  }
  handleSelectTab=(route:any,itm:any)=>{
    console.log("route",route,itm);
    
    urlpatterns.map((value,key)=>{
      if(value.url==route.pathname){
        this.setState({
          expandedKeys:[value.keys],
          defaultExpandAll:true
        })
      }
    })
  }
  getStatusLevel= ()=>{
    let Level = getSessionLevel();
    if(Level=='1'){
      this.setState({
        treeData:firstTreeData,
      })
    }else if(Level=='2'){
      this.setState({
        treeData:twoTreeData,
      })
    }else{
      this.setState({
        treeData:morenTreeData,
      })
    }
  }
  handleSayHelloListener=(level:any)=>{
    
    let {
      clicktwoActive,
      clickoneActive
    }=level
    console.log("level",level,clicktwoActive,clickoneActive);

    if(clickoneActive && !clicktwoActive){
      this.setState({
        treeData:firstTreeData,
         defaultExpandAll:true,
         level
      })
    }
    if(clicktwoActive&&!clickoneActive){
      this.setState({
        treeData:twoTreeData,
        defaultExpandAll:true,
        level
      })
    }
    if(!clickoneActive&&!clicktwoActive){
      this.setState({
        treeData:morenTreeData,
        defaultExpandAll:false
      })
    }
  }
  handelScreen = () => {
    this.setState({reset: !this.state.reset})
  }

  render() {
    return (
      <div>
        <Layout className="dashboard">
          <Header className="db-header" style={{ position: 'fixed', zIndex: 3, width: '100%' }}>
            <HeaderTop props={this.props}></HeaderTop>
          </Header>
          <Layout style={{ marginTop: 70 }} hasSider={true}>
            <Sider
              style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                backgroundColor:'#fff',
                minWidth:200,
                paddingTop:20,
                width:200
              }}
            className="site-layout-background win-no-drag"
             breakpoint="lg" 
             collapsedWidth="200">
            <Tree.DirectoryTree
              multiple
              treeData={this.state.treeData}
              defaultSelectedKeys={['0']}
              defaultExpandAll={this.state.defaultExpandAll}
              selectedKeys={this.state.expandedKeys}
              expandAction={false}
              autoExpandParent={true}
            />  
            </Sider>
            <Layout  style={{ marginLeft: 224,
                marginTop: 20,
                marginRight:24,
                minWidth:800,
                minHeight:'100vh',
                }}>
              <BreadCrumb/>
              <Content
                className="site-layout-background win-no-drag"
                style={{
                  padding: '24px',
                  margin: 0,
                  minHeight: 280,
                }}
              >
                <Switch>
                  <MainRoutes />
                </Switch>
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </div>
    );
  }
}

export default withRouter(HomePage);
